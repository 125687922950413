import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import grayband3 from "img/graybands/2021-05-03 18.40.24.jpg";
import grayband4 from "img/graybands/2021-05-03 18.42.11.jpg";
import grayband5 from "img/graybands/20210910-DSC_3422.jpg";
import grayband6 from "img/graybands/20210910-DSC_3444.jpg";
import grayband7 from "img/graybands/20221008-DSC_7149.jpg";
import grayband8 from "img/graybands/20230711-DSC_1637.jpg";
import grayband9 from "img/graybands/20230813-DSC_2221-Edit.jpg";

const imageData = [
  {
    img: grayband3,
    caption: "2021, 16 Lasers with the lump visible in his tail",
  },
  {
    img: grayband4,
    caption: "2021, 16 Lasers, again showing the lump in his tail",
  },
  { img: grayband5, caption: "2021, 16 Lasers" },
  { img: grayband7, caption: "2022, 16 Lasers" },
  { img: grayband8, caption: "2023, 16 Lasers" },
  { img: grayband9, caption: "2023, 16 Lasers" },
];

const SixteenLasers = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>16 Lasers</title>
        <meta
          name="description"
          content="See the Gray-banded Kingsnakes (Lampropeltis alterna)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/16lasers" />
      </Helmet>
      <HeaderText title="16 Lasers" subtitle="Gray-banded Kingsnake" />

      <AnimalCard
        name="16 Lasers"
        gender="male"
        birthyear="2016"
        imageName={grayband6}
        traits=""
        acqYear="2016"
        acqNotes="Joel Reep, Prismatic Reptiles, Wasatch Reptile Expo"
        desc=""
      />

      <BodyText>
        16 Lasers gets his name from the number of orange bands circling his
        body. My 5-year old came up with the name. It is kind of surprising to
        me that when I bring guests into the snake room and show them 30+ snakes
        representing so many species that frequently 16 Lasers is the favorite
        snake for so many people. Such a beautiful animal.
      </BodyText>
      <BodyText>
        About 3 or 4 years after we got him 16 Lasers developed a noticeably
        thick bulge near his tail. I gave him baths in warm water and tried
        palpating him to see if it would pass but without success. The vet I
        took him to told me that without x-rays and expensive surgery there
        wasn't anything more to do. I feel bad for him but he has lived for
        several years now in this condition and he eats and defecates just fine
        and shows no signs of stress due to his condition, but I'm sure the
        clock is ticking for him.
      </BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default SixteenLasers;
