import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";

import maleRcPastelImg from "img/boaconstrictors/20221028172710.681-Male10a-1500x1500s.jpg";
import maleRcPastelImg2 from "img/boaconstrictors/20230602-DSC_0938.jpg";
import maleRcPastelImg3 from "img/boaconstrictors/20230602-DSC_0934.jpg";
import maleRcPastelImg4 from "img/boaconstrictors/20230923-DSC_3827.jpg";
import maleRcPastelImg5 from "img/boaconstrictors/20230923-DSC_3832.jpg";

const imageData = [
  { img: maleRcPastelImg2, caption: "2023" },
  { img: maleRcPastelImg3, caption: "2023" },
  { img: maleRcPastelImg4, caption: "2023" },
  { img: maleRcPastelImg5, caption: "2023" },
];

const RCPastelMale = () => {
  return (
    <ContentBox>
      
      <HeaderText 
        title="" 
        subtitle="Boa Constrictor" 
      />

      <AnimalCard
        name=""
        gender="male"
        birthyear="2022"
        imageName={maleRcPastelImg}
        traits="RC Pastel, VPI T+ Albino, Jungle"
        acqYear="2022"
        acqNotes="Richard Ceniceros Reptiles"
        desc=""
      />

      <BodyText></BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    
    </ContentBox>
  );
};

export default RCPastelMale;
