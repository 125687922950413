import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import moose1 from "img/dumerils/2019-07-14 20.17.26.jpg";
import moose2 from "img/dumerils/20200912-DSC_8715.jpg";
import moose4 from "img/dumerils/20200912-DSC_8719.jpg";
import moose5 from "img/dumerils/20200912-DSC_8726.jpg";
import moose6 from "img/dumerils/20210202-DSC_9637.jpg";
import moose8 from "img/dumerils/Img2010-05-29_3416.jpg";
import moose9 from "img/dumerils/Male 2015-07-05.jpg";
import moose10 from "img/dumerils/Moose - 10.31.2000.jpg";
import moose11 from "img/dumerils/20210825-DSC_2985.jpg";
import moose12 from "img/dumerils/20230317-DSC_8057.jpg";
import Video from "components/video";

const imageData = [
  { img: moose10, caption: "2000, Moose" },
  { img: moose8, caption: "2010, Moose" },
  { img: moose1, caption: "2019, A Moose once bit my sister" },
  { img: moose2, caption: "2020, Moose" },
  { img: moose4, caption: "2020, Moose" },
  { img: moose5, caption: "2020, Moose" },
  { img: moose6, caption: "2021, Moose" },
  { img: moose11, caption: "2021, Moose" },
  { img: moose12, caption: "2023, Moose" },
];

const Moose = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Moose</title>
        <meta
          name="description"
          content="See the Dumeril's Boas (Acrantophis dumerili)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/moose" />
      </Helmet>
      <HeaderText title="Moose" subtitle="Dumeril's Boa" />

      <AnimalCard
        name="Moose"
        gender="male"
        birthyear="1999"
        imageName={moose9}
        traits=""
        acqYear="2000"
        acqNotes="Gary Sipperley/San Diego Reptile Breeders, San Diego Reptile Show, July 2000"
        desc=""
      />

      <BodyText>
        Moose had the distinction of being the "safe" adult Dumerils for a long
        time until one afternoon when we had him out in the backyard and he was
        being passed around from one child to another and finally, after he was
        handed off to my brother, he decided he was done for the day and let my
        brother know by sinking a lot of teeth into his arm. Point taken. We put
        him back in his home. Glad it was my brother too and not one of the
        kids.
      </BodyText>
      <Video
        src="https://www.youtube.com/embed/92rV-8qkY_I"
        caption="Backyard time with Moose, our Dumerils boa"
      />
      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Moose;
