import { Helmet } from "react-helmet";
import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import Image from "components/image";
import ImageGrid from "components/imagegrid";
import Video from "components/video";

// Images
import gopher2 from "img/gbgopher/Img_2016-09-05_0934.jpg";
import gopher5 from "img/gbgopher/20211003-DSC_4670.jpg";
import gopher6 from "img/gbgopher/Img2007-05-09_0005.jpg";
import gopher7 from "img/gbgopher/Img2007-09-01_0017.jpg";
import gopher8 from "img/gbgopher/Oct 052.jpg";

import gopher9 from "img/gbgopher/Img2010-08-02_3814.jpg";
import gopher10 from "img/gbgopher/2019-07-04 09.44.08.jpg";
import gopher11 from "img/gbgopher/20190514-DSC_6941.jpg";
import gopher12 from "img/gbgopher/20190615-DSC_7053.jpg";
import gopher13 from "img/gbgopher/20190615-DSC_7059.jpg";
import gopher14 from "img/gbgopher/20220514-DSC_6211.jpg";
import gopher15 from "img/gbgopher/DSC_5693-9.jpg";
import gopher16 from "img/gbgopher/DSC_5739-15.jpg";
import gopher17 from "img/gbgopher/img045a.jpg";

const albumData = [
  { img: gopher6, caption: "2007, wild-caught gopher snake" },
  { img: gopher7, caption: "2007, wild-caught gopher snake" },
  {
    img: gopher15,
    caption: "2018, a uniquely patterned Great Basin Gopher snake",
  },
  { img: gopher16, caption: "2018, Great Basin Gopher snake" },
  {
    img: gopher10,
    caption: "2019, mountain biking and herping can go together",
  },
  { img: gopher11, caption: "2019, Great Basin Gopher snake" },
  { img: gopher12, caption: "2019, Great Basin Gopher snake" },
  { img: gopher13, caption: "2019, herping for Great Basin Gopher snakes" },
];

const animalGridData = [
  {
    id: "grit",
    name: "Grit",
    gender: "male",
    birthyear: "",
    img: gopher5,
    traits: "",
  },
];

const GreatBasinGopherSnake = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Great Basin Gopher Snakes</title>
        <meta
          name="description"
          content="See the Great Basin Gopher Snakes (Pituophis catenifer deserticola)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/great-basin-gopher-snake" />
      </Helmet>
      <HeaderText
        title="Great Basin Gopher Snakes"
        subtitle="Pituophis catenifer deserticola"
      />
      <AnimalGrid data={animalGridData} />
      <BodyText>
        In order to talk about Great Basin Gopher snakes I have to talk about
        field herping here in Utah. Most of our interaction with the species has
        come out in the wild. I can say that I have seen at least 200 of them in
        the field and that comes from only going out occasionally. We've had
        nights where we have found as many as 8 different specimens.
      </BodyText>
      <Image src={gopher14} caption="2022, Great Basin Gopher snake" />
      <Video
        src="https://www.youtube.com/embed/aeYzVsoiKUw"
        caption="Hunting for Gopher Snakes in Utah"
      />
      <BodyText>
        Great Basin gophers are fun to find in the wild because they are large
        (4-5'), they don't move very fast, and they are pretty easy-going when
        it comes to picking a wild one up. You do have to
        <i>
          {" "}
          be very careful that you don't confuse a Great Basin Rattlesnake with
          a Gopher snake.{" "}
        </i>
        They look very similar in color and pattern so it's mostly the shape of
        the body, the shape of the head, and of course seeing a rattle that
        helps distiguish between the two. And don't count on rattlesnakes
        rattling when you find them. More often than not the rattlesnakes here
        don't rattle unless you provoke them.
      </BodyText>
      <Image
        src={gopher9}
        caption="2010, this is a Great Basin Rattlesnake, not a Gopher snake"
      />
      <Image
        src={gopher17}
        caption="2004, a baby Great Basin Gopher snake flattening his head to look like a rattlesnake maybe?"
      />
      <BodyText>
        On two occasions we've kept Great Basin Gopher snakes as pets. The first
        one came in the mid 2010s when a neighbor found a baby in their
        backyard. They weren't sure how to take care of it and after it bounced
        around to a few different homes it landed in our home for a couple of
        years before bouncing again and ending up with a breeder in a town
        nearby.
      </BodyText>
      <Image
        src={gopher2}
        caption="2016, finding Grit in the canyon with the family"
      />
      <BodyText>
        The second time we kept a Great Basin gopher snake was when we had a
        family Jeep outing up in the canyon and one of my sons found one. I had
        been giving reptile presentations to school classes recently and had
        been wanting to show kids what the local snakes looked like so we kept
        this one that we found and named him Grit, like sandpaper.
      </BodyText>
      <Image src={gopher8} caption="2003, an albino San Diego Gopher snake" />
      <BodyText>
        There was a time around 2000 when I received a pair of albino San Diego
        Gopher snakes from a friend of mine. They were babies at the time but I
        raised them up and bred them.
      </BodyText>
      <BodyText>
        Over the years the Utah DWR has had different regulations around keeping
        more than a couple of any native snake species and so over the years
        I've become cynical enough about our local government and its policies
        that I now have zero interest in breeding Great Basin Gopher snakes or
        even keeping other native herps. But there are a few people around who
        are breeding them so they should be readily available to people in the
        hobby who want to keep them.
      </BodyText>
      <ImageGrid imageData={albumData} maxWidth="900" />
    </ContentBox>
  );
};

export default GreatBasinGopherSnake;
