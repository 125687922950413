import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import jinx1 from "img/jungles/StripedFemaleJungle4.jpg";
import jinx2 from "img/jungles/DSC_4771.jpg";
import jinx4 from "img/jungles/DSC_4823.jpg";
import jinx5 from "img/jungles/Scans 066.jpg";
import jinx8 from "img/jungles/StripedFemaleJungle3.jpg";
import jinx9 from "img/jungles/2016-07-24 21.00.27.jpg";
import jinx10 from "img/jungles/20221008-DSC_7141.jpg";
import jinx11 from "img/jungles/20220421-DSC_6102.jpg";
import jinx12 from "img/jungles/20221008-DSC_7135.jpg";
import jinx13 from "img/jungles/20221008-DSC_7137.jpg";
import jinx14 from "img/jungles/20230811-DSC_2182-Edit.jpg";
import jinx15 from "img/jungles/20230923-DSC_3846.jpg";
import jinx16 from "img/jungles/20230923-DSC_3840.jpg";

const imageData = [
  { img: jinx1, caption: "1998, Jinx" },
  { img: jinx8, caption: "1998, Jinx" },
  { img: jinx5, caption: "2002, Jinx" },
  { img: jinx9, caption: "2016, Jinx" },
  { img: jinx2, caption: "2017, Jinx" },
  { img: jinx11, caption: "2022, Jinx" },
  { img: jinx10, caption: "2022, Jinx" },
  { img: jinx12, caption: "2022, Jinx" },
  { img: jinx13, caption: "2022, Jinx" },
  { img: jinx14, caption: "2023, Jinx" },
  { img: jinx15, caption: "2023, Jinx" },
  { img: jinx16, caption: "2023, Jinx" },
];

const Jinx = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Jinx</title>
        <meta
          name="description"
          content="See the Jungle Carpet Pythons (Morelia spilota cheynei)
            that are being kept here and learn more about their captive husbandry."
        />
        <link
          rel="canonical"
          href="https://www.gcreptiles.com/jinx"
        />
      </Helmet>
      <HeaderText title="Jinx" subtitle="Jungle Carpet Python" />

      <AnimalCard
        name="Jinx"
        gender="female"
        birthyear="1997"
        imageName={jinx4}
        traits=""
        acqYear="1998"
        acqNotes="Tracy Barker, VPI"
        desc=""
      />

      <BodyText>
        When I got Jinx from VPI I put her in a cage with a hide, a water bowl,
        and newspaper for substrate. But I ran into a problem. She wouldn't eat.
        I was buying live fuzzy mice and when I put them in her cage she would
        just move away from them. Somehow I got the idea that I should cut two
        holes in the sides of her plastic bin and put a piece of pvc through the
        holes so that she could climb up there and survey her domain like a
        queen. Once I did that, she assumed the pose usually taken by green tree
        pythons and from the perch she began eating just fine. So now I consider
        the snakes feeling of security when I'm setting up young animals.
      </BodyText>
      <BodyText>
        As far as breeding Jinx, I've paired her a few times with Pirate Hans
        but I really haven't done much to provide environmental cues or anything
        that might stimulate breeding. In the 2000s I had a thermostat for their
        cages but it didn't have a night-drop feature so I had to manually
        change the setpoint every night and every morning and also try to adjust
        for seasonal variation. I didn't do a very good job of that. Needless to
        say the breeding wasn't successful except for one year in the early
        2010s when Jinx produced several eggs of which two were viable and one
        baby hatched but did not survive.
      </BodyText>
      <BodyText>
        Now that she is in her late 20s I thought I would try her one more time
        using Tarzan and we'll see if there is any success. If not, I don't
        think I'll continue making the attempts with her.
      </BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Jinx;
