import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import mello1 from "img/papuancarpets/20210128-DSC_9595.jpg";
import mello3 from "img/papuancarpets/20-PC-PAP-F2.png";
import mello4 from "img/papuancarpets/20230503-DSC_9333.jpg";
import mello5 from "img/papuancarpets/20230813-DSC_2218.jpg";
import mello6 from "img/papuancarpets/20231110-DSC_5132-Edit.jpg";

const imageData = [
  { img: mello3, caption: "2020, Mello Yello, pic from breeder" },
  { img: mello4, caption: "2023, Mello Yello" },
  { img: mello5, caption: "2023, Mello Yello" },
  { img: mello6, caption: "2023, Mello Yello" },
];

const MelloYello = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Mello Yello</title>
        <meta
          name="description"
          content="See the Papuan Carpet Pythons (Morelia spilota variegata)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/mello" />
      </Helmet>
      <HeaderText title="Mello Yello" subtitle="Papuan Carpet Python" />

      <AnimalCard
        name="Mello Yello"
        gender="female"
        birthyear="2020"
        imageName={mello1}
        traits=""
        acqYear="2021"
        acqNotes="Nick Mutton, Inland Reptiles"
        desc=""
      />

      <BodyText></BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default MelloYello;
