import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import Image from "components/image";
import ImageGrid from "components/imagegrid";
import { Helmet } from "react-helmet";

import bryceAndBallImg from "img/ballpythons/01015.jpg";
import bpTailsLocked from "img/ballpythons/2017-08-30 09.07.49-2.jpg";
import fireflyAndAlienAndEggs from "img/ballpythons/2019-06-30 13.09.58.jpg";
import wrappedAroundBowl from "img/ballpythons/2019-07-07 18.46.18.jpg";
import fullOfEggs from "img/ballpythons/2019-07-29 12.49.29.jpg";
import babies1 from "img/ballpythons/20190910-DSC_7353.jpg";
import fireflyImg from "img/ballpythons/20210821-DSC_2927.jpg";
import cheesecakeImg from "img/ballpythons/20221102-DSC_7275.jpg";
import alfredoImg from "img/ballpythons/20221102-DSC_7289.jpg";
import albBabies from "img/ballpythons/20230211-DSC_7702.jpg";
import archiveFemale2 from "img/ballpythons/DSCN0540a.jpg";
import alienImg from "img/ballpythons/DSC_6015-4.jpg";
import archiveFemale1 from "img/ballpythons/FemaleBall.jpg";
import curvedDisplay1Img from "img/ballpythons/Img_2013-09-28_00561.jpg";
import curvedDisplay2Img from "img/ballpythons/Img_2013-09-28_00563.jpg";
import smallFett1 from "img/ballpythons/Img_2013-09-29_00564.jpg";
import smallFett2 from "img/ballpythons/Img_2013-09-29_00565.jpg";
import fett2015 from "img/ballpythons/Img_2015-06-04_7061.jpg";
import archiveMale1 from "img/ballpythons/Scans 023.jpg";
import earlyBallOnEggsImg from "img/ballpythons/Scans 182.jpg";
import alienEscapeImg from "img/ballpythons/Screenshot from 2019-12-19 11-15-59.png";
import kidsWithBps from "img/ballpythons/2017-12-15 20.34.04.jpg";

const animalGridData = [
  {
    id: "alfredo",
    name: "Alfredo",
    gender: "male",
    birthyear: "2012",
    img: alfredoImg,
    traits: "Hypo Pastel (Ghost)",
  },
  {
    id: "alien",
    name: "Alien",
    gender: "male",
    birthyear: "2015",
    img: alienImg,
    traits: "Albino Black Pastel",
  },
  {
    id: "cheesecake",
    name: "Cheesecake",
    gender: "female",
    birthyear: "2014",
    img: cheesecakeImg,
    traits: "Albino",
  },
  {
    id: "firefly",
    name: "Firefly",
    gender: "female",
    birthyear: "2015",
    img: fireflyImg,
    traits: "Fire Pastel",
  },
];

const earlyBPData = [
  {
    img: archiveFemale1,
    caption: "My very first ball python",
  },
  {
    img: archiveFemale2,
    caption: "Another female",
  },
  {
    img: archiveMale1,
    caption: "Male that wouldn't eat for previous owner",
  },
  {
    img: earlyBallOnEggsImg,
    caption: "Early breeding success with ball pythons",
  },
  {
    img: bryceAndBallImg,
    caption:
      "Look at the teeth on that creature. The poor snake could have been bit.",
  },
];

const alfredoFettData = [
  {
    img: smallFett1,
    caption: "Fettucine, 2013",
  },
  {
    img: curvedDisplay1Img,
    caption:
      "No, this rounded-front cage was not a good choice for ball pythons",
  },
  {
    img: curvedDisplay2Img,
    caption: "But they did like the subterranean feature",
  },
  {
    img: smallFett2,
    caption: "More Fettucine, 2013",
  },
  {
    img: fett2015,
    caption: "Fettucine, 2015",
  },
];

const bpBreedingData = [
  {
    img: fireflyAndAlienAndEggs,
    caption: "Not sure but I'm guessing this girl might be laying eggs soon",
  },
  {
    img: bpTailsLocked,
    caption: "A male and female ball python, caught in the act",
  },
  {
    img: wrappedAroundBowl,
    caption: "Females frequently wrap their water bowls when gravid",
  },
  {
    img: fullOfEggs,
    caption: "Cheesecake, obviously very full with eggs",
  },
  {
    img: babies1,
    caption: "A nice palette of colors in this litter",
  },
  {
    img: albBabies,
    caption:
      "When you breed albino to albino you tend to get a lot of ... albinos",
  },
];

const BallPython = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Ball Pythons</title>
        <meta
          name="description"
          content="See the Ball Pythons (Python regius)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/ball-pythons" />
      </Helmet>

      <HeaderText title="Ball Pythons" subtitle="Python regius" />

      <AnimalGrid data={animalGridData} />

      <BodyText>
        I happen to love ball pythons. Not sure why some breeders feel the need
        to bash on the species. Yes, ball pythons dominate reptile shows but
        people who are new to the reptile hobby also dominate reptile shows and
        ball pythons make great pets for most new keepers. As far as handling
        goes, some of mine will sit still for an hour without moving, so after
        wrangling one of my feisty animals it can be really nice to pull out a
        ball python and not worry about losing my life in the process. And it's
        always nice to have animals that you can safely hand off to guests.
      </BodyText>

      <BodyText>
        Back in the early 2000's I owned 3 ball pythons. Of course at the time
        the only genetic morph that was available for a reasonable price was
        "normal" so all of mine were normals, or "wild-types". I remember in the
        late 90's and early 00's when albino and piebald ball pythons sold for
        $5,000 each and it was way cool to be able to buy animals like that for
        just a few hundred dollars a decade or so later.
      </BodyText>

      <ImageGrid imageData={earlyBPData} maxWidth="900" />

      <BodyText>
        I got my first ball python - a female - from a pet store, and I was
        given a male from someone who wasn't able to get it to eat. The snake
        had gone 9 or 10 months without food but I put it in a homemade rack and
        made sure it had a secure place to hide and heat and sure enough it
        started eating right away. I also had another, smaller female but I
        don't remember her story.
      </BodyText>

      <BodyText>
        I was successful at getting the larger female to breed with the male but
        I was not able to incubate the eggs correctly and they all perished. But
        it was very exciting to see the first part of the snake reproductive
        lifecycle taking place right in our home.
      </BodyText>

      <BodyText>
        Fast-forward to 2013 and I was at the Wasatch Reptile Expo in Salt Lake
        City when I saw some beautiful ball pythons on The Snake Keeper's table.
        I bought a male hypo pastel (Alfredo) and a female spider pastel
        (Fettucine) and planned to breed them when they got big enough.
        Unfortunately, when I put them together a few years later I witnessed
        combat between the two, and that's when I found that I actually had two
        males. Also, I was not aware of the neurological issues caused by the
        spider gene but I never witnessed any symptoms of that in Fettucine.
      </BodyText>

      <ImageGrid imageData={alfredoFettData} maxWidth="900" />

      <BodyText>
        Getting those ball pythons sparked life into my reptile-keeping passion
        again and soon I added other species to the mix in addition to a couple
        new ball pythons. I bought the albino ball that I always wanted and gave
        it to my son for his 10th birthday. I also bought an albino black
        pastel, and a vanilla mojave. There wasn't much of a plan obviously.
      </BodyText>

      <BodyText>
        I've bred the balls a few times and our family enjoys seeing the
        offspring. One of the things about reptile breeding that other people
        are good at is being able to detect if your snake is gravid (pregnant).
        I am not one of those people. The best way that I've found of detecting
        that a snake is gravid is when I open her cage and she's wrapped around
        a clutch of eggs. That seems to happen a lot for me. One time I still
        had the male in the cage when the female dropped her eggs haha. Poor
        guy.
      </BodyText>

      <ImageGrid imageData={bpBreedingData} maxWidth="900" />

      <BodyText>
        It's hard to admit that I've had a snake escape, but it did happen one
        time. Alien, our albino black pastel ball python, got out and I knew he
        was somewhere in the room. Because I worked for a home security company
        at the time I had a security camera in the snake room. Every now and
        then while I was at work I would check the camera to see if Alien
        happened to be on the crawl. I got lucky. When I saw the image below, I
        called my wife from work and she ran down to the room to capture him.
      </BodyText>

      <Image
        src={alienEscapeImg}
        caption="See if you can spot the escapee in this picture"
      />

      <Image src={kidsWithBps} caption="2017, and everyone has a ball python" />
    </ContentBox>
  );
};

export default BallPython;
