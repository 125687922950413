import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import Image from "components/image";
import ImageGrid from "components/imagegrid";

import cuddles5 from "img/dumerils/Female 2015-07-05-2.jpg";
import cuddles1 from "img/dumerils/Img2010-05-29_3408-2.jpg";
import cuddles2 from "img/dumerils/Img2010-05-29_3404-2.jpg";
import cuddles3 from "img/dumerils/AD001_002.jpg";
import cuddles4 from "img/dumerils/FemaleDumeril.jpg";
import cuddles6 from "img/dumerils/20200912-DSC_8687.jpg";
import cuddles8 from "img/dumerils/20210911-DSC_3567.jpg";
import cuddles9 from "img/dumerils/20211001-DSC_4393.jpg";
import dum1 from "img/dumerils/Img2007-04-27_0003.jpg";
import dum4 from "img/dumerils/2021-01-28 09.38.44 (2).jpg";
import dum6 from "img/dumerils/20181220-D1.jpg";
import dum7 from "img/dumerils/20191002-DSC_7749.jpg";

import dum8 from "img/dumerils/Scans 001.jpg";

const imageData = [
  { img: cuddles4, caption: "2000, Cuddles" },
  { img: cuddles3, caption: "2000, Cuddles" },
  { img: dum1, caption: "2007, babies" },
  { img: cuddles1, caption: "2010, Cuddles" },
  { img: cuddles2, caption: "2010, Cuddles" },
  { img: dum6, caption: "2018, baby" },
  { img: dum7, caption: "2019, baby" },
  { img: cuddles6, caption: "2020, Cuddles" },
  { img: dum4, caption: "Jan 2021, Cuddles and Moose lock-up" },
  { img: cuddles8, caption: "2021, Cuddles" },
  { img: cuddles9, caption: "2021, Cuddles" },
];

const Cuddles = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Cuddles</title>
        <meta
          name="description"
          content="See the Dumeril's Boas (Acrantophis dumerili)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/cuddles" />
      </Helmet>
      <HeaderText title="Cuddles" subtitle="Dumeril's Boa" />

      <AnimalCard
        name="Cuddles"
        gender="female"
        birthyear="1999"
        imageName={cuddles5}
        traits=""
        acqYear="2000"
        acqNotes="Gary Sipperley/San Diego Reptile Breeders, San Diego Reptile Show, July 2000"
        desc=""
      />

      <BodyText>
        Cuddles was a bit defensive in her early years and still earns an extra
        dose of respect when being handled. However, she hasn't bitten me for at
        least a decade. One memorable bite from her came on a night before our
        family was heading out of the country for a trip. It was about 1 AM and
        I was trying to get all of the animals fed before leaving when Cuddles
        in her zeal to attack everything that came close to her cage, bit my arm
        and constricted. I couldn't pry her off. I ended up holding my arm under
        cold water in the bathtub for about 15 minutes before she'd let go. This
        was on a frantic night when we had to get up at 5 AM to drive a family
        with little kids to the airport. Nice timing.
      </BodyText>
      <Image src={dum8} caption="2002, Cuddles with my son" />
      <BodyText>
        But Cuddles has been an amazingly prolific momma snake. As of this
        writing she has produced 7 litters of babies and looks like she'll
        produce again this summer. I don't breed her in back to back years, and
        she tends to produce 9-11 babies each time. It's interesting that there
        has been a decent amount of variability in appearance of some of her
        babies. A few times she has produced really high pink animals, sometimes
        there are animals with especially dark saddles.
      </BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Cuddles;
