import * as React from "react";

const Anchor = ({ href, children }) => {
  return (
    <>
      <a rel="noopener noreferrer" target="_blank" href={href}>
        {children}
      </a>
    </>
  );
};

export default Anchor;
