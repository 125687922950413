import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import crushImg from "img/boaconstrictors/20210831-DSC_3297.jpg";
import crush1 from "img/boaconstrictors/20190505-DSC_6861.jpg";
import crush2 from "img/boaconstrictors/20190505-DSC_6870.jpg";
import crush3 from "img/boaconstrictors/20210831-DSC_3194.jpg";
import crush4 from "img/boaconstrictors/20210831-DSC_3237.jpg";
import crush5 from "img/boaconstrictors/20210831-DSC_3279.jpg";
import crush6 from "img/boaconstrictors/20221120-DSC_7326.jpg";

const imageData = [
  { img: crush1, caption: "Crush at age 2 in 2019" },
  { img: crush3, caption: "Crush at age 4 in 2021" },
  { img: crush2, caption: "Crush in 2019" },
  { img: crush4, caption: "Crush in 2021" },
  { img: crush5, caption: "Crush in 2021" },
  { img: crush6, caption: "Crush, 2022" },
];

const Crush = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Crush</title>
        <meta
          name="description"
          content="See the Boa Constrictors (Boa imperator)
            that are being kept here and learn more about their captive husbandry."
        />
        <link
          rel="canonical"
          href="https://www.gcreptiles.com/crush"
        />
      </Helmet>
      <HeaderText title="Crush" subtitle="Boa Constrictor" />

      <AnimalCard
        name="Crush"
        gender="male"
        birthyear="2017"
        imageName={crushImg}
        traits='Sharp Albino, Hypo, het Anery ("Sunglow het Anery")'
        acqYear="2017"
        acqNotes=""
        desc=""
      />

      <BodyText>
        Crush became my first boa constrictor in 2017 when I got him on trade
        for some baby Dumeril's boas. He's been great in every way and I'm
        hoping that he and Blizzard produce some babies soon, preferably without
        any deformities due to both being albinos. I'm especially interested in
        seeing if he really is het Anery like I was told when I got him. Time
        should tell.
      </BodyText>

      <BodyText>
        Incidentally, while I like the hypo gene when combined with albino (aka
        "Sunglow") I've decided that I'm not a fan of hypo in most other combos.
        I really like having pattern definition and so plain hypos or VPI hypos
        and others just don't rock my boat.
      </BodyText>

      <BodyText>
        I love the age progression in the first two photos below. And yes,
        that's the same child two years apart as well.
      </BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Crush;
