import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import tarzan2 from "img/jungles/20210926-DSC_4288.jpg";
import tarzan3 from "img/jungles/20210926-DSC_4305.jpg";
import tarzan4 from "img/jungles/20210926-DSC_4284.jpg";
import tarzan5 from "img/jungles/20230206-DSC_7688.jpg";
import tarzan6 from "img/jungles/20211118-DSC_4991.jpg";
import tarzan7 from "img/jungles/20211209-DSC_5159.jpg";
import tarzan9 from "img/jungles/20221120-DSC_7320.jpg";
import Video from "components/video";
import tarzan10 from "img/jungles/20230711-DSC_1545.jpg";
import tarzan11 from "img/jungles/20230711-DSC_1516.jpg";
import tarzan12 from "img/jungles/20230711-DSC_1526.jpg";
import tarzan13 from "img/jungles/20230711-DSC_1535.jpg";

const imageData = [
  { img: tarzan3, caption: "2021, Tarzan" },
  { img: tarzan4, caption: "2021, Tarzan" },
  { img: tarzan6, caption: "2021, Tarzan" },
  { img: tarzan7, caption: "2021, Tarzan" },
  { img: tarzan9, caption: "2022, Tarzan" },
  { img: tarzan5, caption: "2023, Tarzan, wrapping the heat in the AM" },
  { img: tarzan10, caption: "2023, Tarzan" },
  { img: tarzan11, caption: "2023, Tarzan" },
  { img: tarzan12, caption: "2023, Tarzan" },
  { img: tarzan13, caption: "2023, Tarzan" },
];

const Tarzan = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Tarzan</title>
        <meta
          name="description"
          content="See the Jungle Carpet Pythons (Morelia spilota cheynei)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/tarzan" />
      </Helmet>
      <HeaderText title="Tarzan" subtitle="Jungle Carpet Python" />

      <AnimalCard
        name="Tarzan"
        gender="male"
        birthyear="2015"
        imageName={tarzan2}
        traits=""
        acqYear="2021"
        acqNotes="local animal rescue"
        desc=""
      />

      <BodyText>
        I very rarely look at local online classified ads for snakes but I did a
        few years ago and was very surprised to see this snake for sale from
        someone in the Salt Lake City area. He was already an adult and still
        had great coloration and the price was right so I hurried and bought
        him. The previous owner runs a reptile rescue and they warned me that he
        was pretty hissy, but after having him for a few months he calmed down.
        I don't completely trust him to not bite yet but we're getting there.
      </BodyText>
      <Video
        src="https://www.youtube.com/embed/oqhj3lAmj58"
        caption="Tarzan, my jungle carpet python"
      />
      <BodyText>
        After his first winter with me I bred him with my jaguar carpet, Ute,
        and they were successful and she laid a small clutch of eggs, 3 of which
        hatched and thrived. Now I'm using him to pair with Jinx to see if she
        is receptive at all.
      </BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Tarzan;
