import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import eking12 from "img/easternkings/Eastern Kingsnake (M) from GYP - 2.jpg";
import eking13 from "img/easternkings/20230914-DSC_3631-Edit.jpg";
import eking14 from "img/easternkings/20230914-DSC_3620-Edit.jpg";
import eking15 from "img/easternkings/20230914-DSC_3627-Edit.jpg";

const imageData = [
  { img: eking13, caption: "Sep 2023, looking good" },
  { img: eking14, caption: "Sep 2023, looking good" },
  { img: eking15, caption: "Sep 2023, looking good" },
];

const Blister = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Blister</title>
        <meta
          name="description"
          content="See the Eastern Kingsnakes (Lampropeltis getula)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/blister" />
      </Helmet>
      <HeaderText title="Blister" subtitle="Eastern Kingsnake" />

      <AnimalCard
        name="Blister"
        gender="male"
        birthyear="2019"
        imageName={eking12}
        traits=""
        acqYear="2022"
        acqNotes=""
        desc=""
      />

      <BodyText>
        Blister gets his name due to an unfortunate experience he had with his
        first year of brumation with me. I checked on him every week and during
        the last week or two of brumation noticed that he had gotten blisters on
        his skin and eyes. I assume the cage was too damp, and of course it was
        cold, low 50s F.
      </BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Blister;
