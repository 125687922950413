import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import Image from "components/image";
import ImageGrid from "components/imagegrid";

import firefly1 from "img/ballpythons/20210821-DSC_2927.jpg";
import firefly2 from "img/ballpythons/1287822-1433961780-820812-2.jpg";
import firefly2a from "img/ballpythons/2018-02-03 14.17.01-1.jpg";
import firefly3 from "img/ballpythons/2019-02-23 12.39.22.jpg";
import firefly4 from "img/ballpythons/2019-06-30 13.09.58.jpg";
import firefly5 from "img/ballpythons/20190910-DSC_7353.jpg";
import firefly6 from "img/ballpythons/20191002-DSC_7722.jpg";
import firefly7 from "img/ballpythons/20191126-DSC_7971.jpg";
import firefly8 from "img/ballpythons/20191126-DSC_7944.jpg";
import firefly9 from "img/ballpythons/20191126-DSC_7950.jpg";
import firefly10 from "img/ballpythons/20191126-DSC_7964.jpg";
import firefly11 from "img/ballpythons/20210821-DSC_2914.jpg";
import firefly12 from "img/ballpythons/20211118-DSC_4989.jpg";
import firefly13 from "img/ballpythons/20230601-DSC_0890.jpg";
import firefly14 from "img/ballpythons/20230524-DSC_0546.jpg";
import firefly15 from "img/ballpythons/20230524-DSC_0549.jpg";

const imageData = [
  { img: firefly2a, caption: "Feb 2018" },
  { img: firefly3, caption: "Feb 2019" },
  { img: firefly4, caption: "June 2019, Firefly and Alien with eggs" },
  { img: firefly6, caption: "Pastel black pastel fire ball python" },
  { img: firefly7, caption: "Pastel ball python" },
  { img: firefly8, caption: "Pastel fire ball python" },
  { img: firefly9, caption: "Pastel black pastel fire ball python" },
  { img: firefly10, caption: "Black pastel ball python" },
  { img: firefly11, caption: "2021 at 6 years old" },
  {
    img: firefly12,
    caption: "Nov 2021 update pic of the pastel black pastel fire baby",
  },
  { img: firefly13, caption: "2023, Firefly" },
  { img: firefly14, caption: "2023, Firefly" },
  { img: firefly15, caption: "2023, Firefly" },
];

const Firefly = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Firefly</title>
        <meta
          name="description"
          content="See the Ball Pythons (Python regius)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/firefly" />
      </Helmet>
      <HeaderText title="Firefly" subtitle="Ball Python" />

      <AnimalCard
        name="Firefly"
        gender="female"
        birthyear=""
        imageName={firefly1}
        traits="Fire, Pastel"
        acqYear="2015"
        acqNotes=""
        desc=""
      />

      <BodyText>
        I got Firefly from a local breeder on a trade for a baby Dumerils boa.
      </BodyText>

      <Image
        src={firefly2}
        caption="The picture in the original ad for Firefly"
      />

      <BodyText>
        We got a taste of the craze around ball python genetics when we bred
        Firefly to Alien and got a small litter of four completely different
        looking animals. In this picture below starting from the top-left and
        working around clockwise there is a black pastel, a pastel, a pastel
        black pastel fire, and a pastel fire baby. All are het for albino.
      </BodyText>

      <Image
        src={firefly5}
        caption="Outcome of breeding Firefly with Alien in 2019"
      />

      <BodyText>
        A few years after this breeding we brought back her son, the pastel
        black pastel fire ball python and attempted to breed them together but
        without success.
      </BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Firefly;
