import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import Image from "components/image";
import ImageGrid from "components/imagegrid";

import eking1 from "img/easternkings/2017-12-28 13.31.34.jpg";
import eking3 from "img/easternkings/20210417-DSC_9911.jpg";
import eking4 from "img/easternkings/20210417-DSC_9917.jpg";
import eking5 from "img/easternkings/20210417-DSC_9925.jpg";
import eking7 from "img/easternkings/20210629-DSC_1685.jpg";
import eking8 from "img/easternkings/20211003-DSC_4621.jpg";
import eking9 from "img/easternkings/20211003-DSC_4627.jpg";
import eking11 from "img/easternkings/DSC_5981-1.jpg";
import twig from "img/corns/20190517-DSC_7004.jpg";
import eking12 from "img/easternkings/20230601-DSC_0864-Edit.jpg";
import eking13 from "img/easternkings/20230601-DSC_0853-Edit.jpg";
import eking14 from "img/easternkings/20230601-DSC_0860-Edit.jpg";

const imageData = [
  { img: eking1, caption: "2017, LOL" },
  { img: eking11, caption: "2018, LOL" },
  { img: eking4, caption: "2021, LOL" },
  { img: eking5, caption: "2021, LOL" },
  { img: eking7, caption: "2021, LOL" },
  { img: eking8, caption: "2021, LOL" },
  { img: eking9, caption: "2021, LOL" },
  { img: eking12, caption: "2023, LOL" },
  { img: eking13, caption: "2023, LOL" },
  { img: eking14, caption: "2023, LOL" },
];

const LOL = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>LOL</title>
        <meta
          name="description"
          content="See the Eastern Kingsnakes (Lampropeltis getula)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/lol" />
      </Helmet>
      <HeaderText title="LOL" subtitle="Eastern Kingsnake" />

      <AnimalCard
        name="LOL"
        gender="female"
        birthyear="2015"
        imageName={eking3}
        traits=""
        acqYear="2015"
        acqNotes=""
        desc=""
      />

      <BodyText>
        LOL gets her name from the emoji-like headstamp that she has.
      </BodyText>

      <BodyText>
        I worry that I have broken LOL. When feeding snakes over the years it's
        common for one or two animals to not want to eat on a given night. So I
        have taken the uneaten mouse or rat and offered it to another snake so
        that it doesn't go to waste. Too frequently I have offered the uneaten
        rodent to LOL. And I didn't realize it but she has gotten pretty fat
        from it. Since discovering it I have only fed her one smallish meal once
        per month to try and thin her down a bit.
      </BodyText>

      <Image src={twig} caption="2019, Twig, blood-red corn snake" />
      <BodyText>
        There used to be a beautiful blood-red corn snake named Twig living in
        the cage above LOL. One day I found Twig's sliding glass doors open a
        little and Twig was missing. We couldn't find her anywhere in the room.
        Strangely, LOL's cage below Twig's also had the sliding doors slightly
        open, not enough for LOL to escape but big enough for Twig to fit
        through. The glass doors were closed the night before so the only thing
        I can guess is that Twig got her doors open just enough to escape, and
        then sadly she went down and either she or LOL got LOL's door open just
        enough for Twig to seek safety inside and of course, LOL being a king
        snake took advantage of the opportunity and ate Twig. All of the cages
        got locks on them soon after and LOL has carried the shame from her
        humans in the years since. In our house no body is necessary for a
        murder conviction.
      </BodyText>

      <BodyText>
        I have been excited to attempt breeding LOL with Blister but Blister is
        a little bit smaller than LOL so I have been worried she would eat him.
        He is about 2/3 the length of her but due to me over-feeding her,
        Blister is only about 1/2 her thickness. In the first breeding attempt
        that I made between them I put him in her cage and then sat and watched.
        It was nighttime and she came crawling out from the substrate excitedly
        smelling the air. Sure enough, within 5 minutes she had found Blister
        and bitten him near the tail and was hanging on when I opened the cage
        and pulled him out. I almost wrote off the year and figured I'd let him
        grow for another year before trying again but a couple days later I
        decided to try during the daytime. This time it looked good. LOL came
        out and as Blister circled her cage she circled too and eventually they
        settled down under different hides so I think we're good to go.
      </BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default LOL;
