import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import wiggles1 from "img/dumerils/F9_DSC_8551.jpg";
import wiggles2 from "img/dumerils/20220409-DSC_6055.jpg";
import wiggles4 from "img/dumerils/F9_20200912-DSC_8682.jpg";
import wiggles5 from "img/dumerils/F9_20201002-DSC_8943.jpg";
import wiggles6 from "img/dumerils/2021-05-03 18.12.06.jpg";

const imageData = [
  { img: wiggles4, caption: "Sep 2020, Wiggles" },
  { img: wiggles5, caption: "Oct 2020, Wiggles" },
  { img: wiggles6, caption: "May 2021, Wiggles" },
  { img: wiggles2, caption: "Apr 2022, Wiggles" },
];

const Wiggles = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Wiggles</title>
        <meta
          name="description"
          content="See the Dumeril's Boas (Acrantophis dumerili)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/wiggles" />
      </Helmet>
      <HeaderText title="Wiggles" subtitle="Dumeril's Boa" />

      <AnimalCard
        name="Wiggles"
        gender="female"
        birthyear="2020"
        imageName={wiggles1}
        traits=""
        acqYear="2020"
        acqNotes="self-produced"
        desc=""
      />

      <BodyText></BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Wiggles;
