import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import bacon1 from "img/centralians/19-BR-HSPO-M4.jpg";
import bacon3 from "img/centralians/20210209-DSC_9663.jpg";
import bacon6 from "img/centralians/20210913-DSC_3985.jpg";
import bacon7 from "img/centralians/20220409-DSC_6058.jpg";
import bacon8 from "img/centralians/20230317-DSC_8052.jpg";
import bacon9 from "img/centralians/20230503-DSC_9326.jpg";
import bacon10 from "img/centralians/20231110-DSC_5122.jpg";

const imageData = [
  { img: bacon1, caption: "Baconator in 2020, pic from breeder" },
  { img: bacon3, caption: "2021 Baconator" },
  { img: bacon7, caption: "2022, Baconator" },
  { img: bacon8, caption: "2023, Baconator" },
  { img: bacon9, caption: "2023, Baconator" },
  { img: bacon10, caption: "2023, Baconator" },
];

const Baconator = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Baconator</title>
        <meta
          name="description"
          content="See the Centralian Pythons (Morelia bredli)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/baconator" />
      </Helmet>
      <HeaderText title="Baconator" subtitle="Centralian Python" />

      <AnimalCard
        name="Baconator"
        gender="male"
        birthyear="2019"
        imageName={bacon6}
        traits="Hypomelanistic"
        acqYear="2020"
        acqNotes="Nick Mutton, Inland Reptiles"
        desc=""
      />

      <BodyText></BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Baconator;
