import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";

const Video = ({ src, caption, width, height }) => {
  const wideScreenWidth = useMediaQuery("(min-width:650px)");
  const videoWidth =
    width !== undefined ? width : wideScreenWidth ? "600" : "300";
  const videoHeight =
    height !== undefined ? height : wideScreenWidth ? "338" : "169";

  // TODO - the 'src' needs to be checked for conformity, and if it's a YouTube video
  //  it needs to not have the word 'watch' in it, it should say 'embed'.
  //
  //    These won't work:
  //      * https://youtu.be/GHCYqOYAtqI
  //      * https://www.youtube.com/watch?v=GHCYqOYAtqI
  //
  //    This will work, under the video in YT choose Share and then pick Embed:
  //      * https://www.youtube.com/embed/GHCYqOYAtqI

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: 1,
      }}
    >
      <iframe
        width={videoWidth}
        height={videoHeight}
        src={src}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>

      <Typography variant="caption" sx={{ mt: 2, textAlign: "center" }}>
        {caption}
      </Typography>
    </Box>
  );
};

export default Video;
