import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import ute7 from "img/coastals/20210202-DSC_9655.jpg";
import ute17 from "img/coastals/DSC_5982-1.jpg";
import ute18 from "img/coastals/IMAG0741.jpg";
import ute3 from "img/coastals/2021-05-03 19.02.16.jpg";
import ute9 from "img/coastals/20210507-DSC_0006.jpg";
import ute10 from "img/coastals/20210720-DSC_2119.jpg";
import ute4 from "img/coastals/2022-07-02 11.43.58.jpg";
import ute12 from "img/coastals/20220410-DSC_6061.jpg";
import ute13 from "img/coastals/20220228-DSC_5496.jpg";
import ute14 from "img/coastals/20220305-DSC_5689.jpg";
import ute15 from "img/coastals/20230601-DSC_0892-Edit.jpg";
import ute16 from "img/coastals/20230714-DSC_1640.jpg";
import ute19 from "img/coastals/20230811-DSC_2189.jpg";

const imageData = [
  { img: ute18, caption: "2017, right out of the shipping box" },
  { img: ute17, caption: "2018" },
  { img: ute3, caption: "2021, exploring her upgraded cage" },
  { img: ute9, caption: "2021" },
  { img: ute10, caption: "2021" },
  { img: ute13, caption: "Feb 2022, locked up with Tarzan" },
  { img: ute14, caption: "Mar 2022, snuggling" },
  {
    img: ute12,
    caption:
      "Apr 2022, Ute is gravid now and going for some extra heat first thing in the morning",
  },
  { img: ute4, caption: "Jul 2022, eggs laid, recently shed" },
  { img: ute15, caption: "2023, Ute" },
  { img: ute16, caption: "2023, Ute" },
  { img: ute19, caption: "2023, Ute" },
];

const Ute = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Ute</title>
        <meta
          name="description"
          content="See the Coastal Carpet Pythons (Morelia spilota mcdowelli)
            that are being kept here and learn more about their captive husbandry."
        />
        <link
          rel="canonical"
          href="https://www.gcreptiles.com/ute"
        />
      </Helmet>
      <HeaderText title="Ute" subtitle="Coastal Carpet Python" />

      <AnimalCard
        name="Ute"
        gender="female"
        birthyear="2016"
        imageName={ute7}
        traits="Jaguar"
        acqYear="2016"
        acqNotes="Michael Pennell, Python In a Pear Tree"
        desc=""
      />

      <BodyText>
        Ute gets her name because her head-stamp looks a lot like the logo for a
        certain university that is near my home in Utah. But as an alumnus of
        the rival university, I won't mention the name of the former.
      </BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Ute;
