import * as React from "react";
import theme from "./theme";
import MainAppBar from "./components/mainappbar";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import { Outlet } from "react-router-dom";

export default function App() {
  return (
    <Box>
      <ThemeProvider theme={theme}>
        <MainAppBar />
          <Outlet />
      </ThemeProvider>
    </Box>
  );
}
