import { createTheme } from "@mui/material/styles";

// Be aware that calling createTheme() by itself first and without params creates
// the default Mui theme that has the blue secondary color that we don't want. So
// create the theme with the palette first, and then call it again to get the
// breakpoints and everything correct.

let theme = createTheme({
  palette: {
    // type: "light",
    primary: {
      main: "#395144",
    },
    secondary: {
      light: "#F0EBCE",
      main: "#AA8B56",
      dark: "#846D44",
      contrastText: "",
    },
  },
});

theme = createTheme(theme, {
  typography: {
    fontFamily: '"Arimo", "Segoe UI", "Roboto", "Helvetica", sans-serif',
    fontWeight: 400,
    h1: {
      [theme.breakpoints.down("md")]: {
        fontSize: "5rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "6rem",
      },
    },
    h2: {
      fontFamily: '"Roboto", "Helvetica", sans-serif',
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "2.5rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "3.75rem",
      },
    },
    h3: {
      [theme.breakpoints.down("md")]: {
        fontSize: "2.5rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "3rem",
      },
    },
    h4: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.25rem",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "1.375rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1.5rem",
      },
    },
    h5: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1.5rem",
      },
    },
    h6: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.8rem",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1.15rem",
      },
    },
    body1: {
      maxWidth: 600,
    },
    body2: {
      fontSize: "0.7rem",
    },
    caption: {
      maxWidth: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.8rem",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1.15rem",
      },
    },
    button: {
      // fontStyle: 'italic',
    },
  },
});

export default theme;
