import * as React from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Modal from "@mui/material/Modal";
import { useMediaQuery } from "@mui/material";

const ImageGrid = ({ imageData, maxWidth }) => {
  // Input properties are:
  //    `imageData`   - list of ["img", "caption"] for each image
  //    `maxWidth`    -

  maxWidth = Number(maxWidth);

  // Tracks whether the modal popup is open or not
  const [open, setOpen] = React.useState(false);

  const handleOpen = (index) => {
    setSelectedIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedIndex(0);
    setOpen(false);
  };

  // Index of picture to show enlarged in modal popup.
  // Use the first picture as the default.
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const matches = useMediaQuery("(min-width:400px)");

  return (
    <Box sx={{ maxWidth: maxWidth, mx: "auto", my: 1 }}>
      <ImageList variant="masonry" cols={matches ? 3 : 2} gap={8}>
        {imageData.map((item, index) => (
          <ImageListItem key={item.caption} onClick={() => handleOpen(index)}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.caption}
              loading="lazy"
            />
            <ImageListItemBar
              title={item.caption}
              position="top"
              sx={{ opacity: "75%" }}
            />
          </ImageListItem>
        ))}
      </ImageList>

      <Modal
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            height: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            outline: 0,
          }}
        >
          <figcaption>{imageData[selectedIndex].caption}</figcaption>
          <img
            src={imageData[selectedIndex].img}
            alt={imageData[selectedIndex].caption}
            width="100%"
            height="95%"
            style={{ objectFit: "contain" }}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default ImageGrid;
