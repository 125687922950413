import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const CollectionCard = ({ id, title, smImg, mdImg, sciName }) => {
    const mediumScreenWidth = useMediaQuery("(min-width:400px)");

    return (
      <Card
        sx={{
          width: mediumScreenWidth ? 260 : 155,
          height: mediumScreenWidth ? 320 : 210,
          margin: 1.5,
        }}
      >
        <Link to={`/${id}`} style={{ textDecoration: "none" }}>
          <CardMedia
            component="img"
            height={mediumScreenWidth ? "180" : "107"}
            image={mdImg}
            srcSet={`${smImg} 155w, ${mdImg} 260w`}
            sizes="(max-width: 260) 155px, 260px"
            alt={title}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant={mediumScreenWidth ? "h4" : "h5"}
              component="div"
              color="#423B22"
            >
              {title}
            </Typography>
            <Typography variant={mediumScreenWidth ? "body1" : "body2"} color="#846D44">
              {sciName}
            </Typography>
          </CardContent>
        </Link>
      </Card>
    );
}

export default CollectionCard;
