import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import xavier1 from "img/diamonds/2019-02-26 09.54.39.jpg";
import xavier3 from "img/diamonds/20201114-DSC_9364.jpg";
import xavier4 from "img/diamonds/20210922-DSC_4188.jpg";
import xavier5 from "img/diamonds/20210913-DSC_4032.jpg";
import xavier9 from "img/diamonds/20221008-DSC_7105.jpg";
import xavier10 from "img/diamonds/20230317-DSC_8096.jpg";
import xavier11 from "img/diamonds/20230711-DSC_1602.jpg";
import xavier12 from "img/diamonds/20230711-DSC_1602-2.jpg";
import xavier13 from "img/diamonds/20230711-DSC_1605.jpg";
import xavier14 from "img/diamonds/20230711-DSC_1622.jpg";
import xavier15 from "img/diamonds/20231110-DSC_5135.jpg";

const imageData = [
  { img: xavier1, caption: "2019, Xavier" },
  { img: xavier4, caption: "2021, Xavier" },
  { img: xavier5, caption: "2021, Xavier" },
  { img: xavier9, caption: "2022, Xavier" },
  { img: xavier10, caption: "2023, Xavier" },
  { img: xavier11, caption: "2023, Xavier" },
  { img: xavier12, caption: "2023, Xavier" },
  { img: xavier13, caption: "2023, Xavier" },
  { img: xavier14, caption: "2023, Xavier" },
  { img: xavier15, caption: "2023, Xavier" },
];

const Xavier = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Xavier</title>
        <meta
          name="description"
          content="See the Diamond Pythons (Morelia spilota spilota)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/xavier" />
      </Helmet>
      <HeaderText title="Xavier" subtitle="Diamond Python" />

      <AnimalCard
        name="Xavier"
        gender="male"
        birthyear="2018"
        imageName={xavier3}
        traits=""
        acqYear="2019"
        acqNotes=""
        desc=""
      />

      <BodyText></BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Xavier;
