import { Helmet } from "react-helmet";
import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";

// Images
import honduran1 from "img/hondurans/DSC_5040-12.jpg";
import honduran4 from "img/hondurans/20220302-DSC_5650.jpg";
import honduran5 from "img/hondurans/20221008-DSC_7178.jpg";
import suncka2 from "img/hondurans/20230814-DSC_2229-Edit.jpg";

const albumData = [
  { img: honduran1, caption: "2018, Saturcka" },
  { img: honduran4, caption: "2022, Saturcka" },
];

const animalGridData = [
  {
    id: "saturcka",
    name: "Saturcka",
    gender: "female",
    birthyear: "2015",
    img: honduran5,
    traits: "Albino, Het Hypo",
  },
  {
    id: "suncka",
    name: "Suncka",
    gender: "male",
    birthyear: "2022",
    img: suncka2,
    traits: "Het Albino, Het Hypo, 50% Het Anery",
  },
];

const HonduranMilkSnake = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Honduran Milksnakes</title>
        <meta
          name="description"
          content="See the Honduran Milksnakes (Lampropeltis triangulum hondurensis)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/honduran-milksnake" />
      </Helmet>
      <HeaderText
        title="Honduran Milksnakes"
        subtitle="Lampropeltis triangulum hondurensis"
      />
      <AnimalGrid data={animalGridData} />
      <BodyText>
        I love all snakes that have red or orange on them so of course I love
        Honduran Milksnakes. The wild phenotypes - tricolors and tangerines -
        are amazing, and I like the albino version of each of them as well. And
        this kind of goes against what I just said but the anerythristic version
        is on my wish list too.
      </BodyText>
      <BodyText>
        I bought Saturcka when she was a baby and then it took almost 8 years
        for me to find a suitable partner for her. Now that I have him I have to
        wait a couple more years to get him up to size to breed with her and not
        become a late night snack instead.
      </BodyText>
      <ImageGrid imageData={albumData} maxWidth="900" />
    </ContentBox>
  );
};

export default HonduranMilkSnake;
