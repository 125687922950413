import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";

const Image = ({ src, caption, width, clickable = "true" }) => {
  // Input properties are:
  //    `src`   - of image
  //    `caption` - caption
  //    `width` - of image
  //    `clickable` - bool, "true" or "True" or "TRUE"

  const isClickable = clickable.toLowerCase() === "true";

  const wideScreenWidth = useMediaQuery("(min-width:650px)");

  // If a value was passed in for 'width' then use that value. Otherwise, use either 300 or 600.
  const imgWidth =
    width !== undefined ? width : wideScreenWidth ? "600" : "300";

  // Tracks whether the modal popup is open or not
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    if (isClickable) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    if (isClickable) {
      setOpen(false);
    }
  };

  // TODO - the require() function returns an object, and the image itself will be at
  //  the 'default' property of that object. This is new(?) in recent create-react-app.
  //
  //    <Image src={require("img/azmtnkings/Img_2012-05-31_8618.jpg").default} alt="hey" />

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: 1,
      }}
    >
      {/* Image */}
      <img
        src={src}
        alt={caption}
        width={imgWidth}
        onClick={() => handleOpen()}
      />

      {/* Caption */}
      <Typography variant="caption" sx={{ mt: 2, textAlign: "center" }}>
        {caption}
      </Typography>

      {/* Modal */}
      <Modal
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            height: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: 0,
          }}
        >
          <figcaption>{caption}</figcaption>
          <img
            src={src}
            alt={caption}
            width="100%"
            height="95%"
            style={{ objectFit: "contain" }}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default Image;
