import { Helmet } from "react-helmet";
import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import Image from "components/image";
import ImageGrid from "components/imagegrid";

// Images
import mystique2 from "img/diamonds/08-18-2020 hatching.jpg";
import mystique6 from "img/diamonds/20211118-DSC_5010.jpg";
import mystique7 from "img/diamonds/2021-10-13 16.08.05.jpg";
import mystique8 from "img/diamonds/20221102-DSC_7259.jpg";
import mystique9 from "img/diamonds/20221008-DSC_7130.jpg";
import xavier2 from "img/diamonds/20201114-DSC_9374.jpg";
import xavier3 from "img/diamonds/20201114-DSC_9364.jpg";
import xavier6 from "img/diamonds/20210913-DSC_4052.jpg";
import xavier8 from "img/diamonds/20220302-DSC_5660.jpg";
import magicTime1 from "img/diamonds/unnamed.jpg";
import magicTime2 from "img/diamonds/IMAG0033.jpg";

const albumData = [
  { img: mystique2, caption: "2020, Mystique's clutch, pic from breeder" },
  { img: mystique7, caption: "2021, Mystique" },
  { img: mystique9, caption: "2022, Mystique" },
  { img: mystique8, caption: "2022, Mystique" },
  { img: xavier2, caption: "2020, Xavier" },
  { img: xavier6, caption: "2021, Xavier" },
  { img: xavier8, caption: "2022, Xavier" },
  { img: magicTime1, caption: "2018, Magic Time, pic from breeder" },
];

const animalGridData = [
  {
    id: "xavier",
    name: "Xavier",
    gender: "male",
    birthyear: "2018",
    img: xavier3,
    traits: "",
  },
  {
    id: "mystique",
    name: "Mystique",
    gender: "female",
    birthyear: "2020",
    img: mystique6,
    traits: "",
  },
];

const DiamondPython = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Diamond Pythons</title>
        <meta
          name="description"
          content="See the Diamond Pythons (Morelia spilota spilota)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/diamond-python" />
      </Helmet>
      <HeaderText title="Diamond Pythons" subtitle="Morelia spilota spilota" />

      <AnimalGrid data={animalGridData} />

      <BodyText>
        I have two diamond pythons currently but I feel like I could own 10 and
        still want more. I cool them every winter for 3 months at about 50-55 F
        during the night and then I bring them into the snake room during the
        day and put them on the floor which is in the low 70's F. At night I
        return them to the cold room. Hoping to breed these in 2025.
      </BodyText>

      <Image src={magicTime2} caption="2018, Magic Time" />

      <BodyText>
        I've made many mistakes as a herp keeper but by far my worst came with
        my first diamond python, Magic Time. I bought him from Psychotic Exotics
        and he was the most majestic animal I'd ever had. Everything was going
        well until about a year later when I brought in several new animals and
        didn't quarantine them for long enough. I found out too late that they
        came in with mites. I treated my whole collection several times and I
        believe I didn't wait long enough for the Provent a Mite mist to settle
        before putting Magic Time back in his cage. The next day he was dead, as
        well as a beautiful Jungle Carpet python in another cage. I still kick
        myself over this and have a lump in my stomach thinking about both of
        them.
      </BodyText>

      <ImageGrid imageData={albumData} maxWidth="900" />
    </ContentBox>
  );
};

export default DiamondPython;
