import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";

import alfredo1 from "img/ballpythons/20221102-DSC_7289.jpg";
import alfredo2 from "img/ballpythons/2018-02-03 12.49.14-1.jpg";
import alfredo3 from "img/ballpythons/2018-02-03 12.48.36-1.jpg";
import alfredo4 from "img/ballpythons/2019-02-23 12.43.18.jpg";
import alfredo5 from "img/ballpythons/20221102-DSC_7288.jpg";

const Alfredo = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Alfredo</title>
        <meta
          name="description"
          content="See the Ball Pythons (Python regius)
            that are being kept here and learn more about their captive husbandry."
        />
        <link
          rel="canonical"
          href="https://www.gcreptiles.com/alfredo"
        />
      </Helmet>
      <HeaderText title="Alfredo" subtitle="Ball Python" />

      <AnimalCard
        name="Alfredo"
        gender="male"
        birthyear="2012"
        imageName={alfredo1}
        traits="Hypo, Pastel"
        acqYear="2013"
        acqNotes="Colette Sutherland, TSK, Wasatch Reptile Expo"
        desc=""
      />

      <BodyText>
        Alfredo has the distinct gift of being the one - and maybe only - snake
        that I own that has never bitten anyone. I bought him in 2013 when I
        began increasing my collection after keeping things static for about 10
        years.
      </BodyText>

      <BodyText>
        If there is one thing that is slightly unfortunate about him it is that
        I don't have any real plan for him except to keep him as a pet. He has
        never been bred as his hypomelanistic trait (reduction in black/melanin
        pigmentation) is a recessive trait, and I don't have, nor do I really
        want to get into breeding hypomelanistic ball pythons. But we're
        perfectly content to have a perfectly cool animal to handle.
      </BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Alfredo;

const imageData = [
  {
    img: alfredo2,
    caption: "At 6 years old, still retaining a lot of yellow pigment",
  },
  {
    img: alfredo3,
    caption: "",
  },
  {
    img: alfredo4,
    caption:
      "6 year old weigh-in, 1548 grams, squished into a little plastic bowl haha",
  },
  {
    img: alfredo5,
    caption: "At 9 years old, the yellow pigmentation fading a bit",
  },
];
