import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";

import shelly1 from "img/ebts/20210427-DSC_9944.jpg";
import shelly2 from "img/ebts/DSC_5907-3.jpg";
import shelly4 from "img/ebts/2021-08-28 17.58.38.jpg";
import shelly5 from "img/ebts/2021-04-07 15.36.13.jpg";
import shelly7 from "img/ebts/20210429-DSC_9993.jpg";
import shelly8 from "img/ebts/20230607-DSC_1053.jpg";
import shelly9 from "img/ebts/20230610-DSC_1192.jpg";
import shelly10 from "img/ebts/20230316-DSC_8050.jpg";
import shelly11 from "img/ebts/20230607-DSC_1027.jpg";
import shelly12 from "img/ebts/20230607-DSC_1034.jpg";

const imageData = [
  { img: shelly4, caption: "2018, Shelly, pooping on the scale" },
  { img: shelly2, caption: "2021, Shelly, damaged shell over front right leg" },
  { img: shelly7, caption: "2021, Shelly" },
  { img: shelly5, caption: "2021, Shelly, low-weight right out of brumation" },
  { img: shelly8, caption: "2023, Shelly" },
  { img: shelly9, caption: "2023, Shelly" },
  { img: shelly10, caption: "2023, Shelly" },
  { img: shelly11, caption: "2023, Shelly" },
  { img: shelly12, caption: "2023, Shelly" },
];

const Shelly = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Shelly</title>
        <meta
          name="description"
          content="See the Eastern Box Turtles (Terrapene carolina carolina)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/shelly" />
      </Helmet>
      <HeaderText title="Shelly" subtitle="Eastern Box Turtle" />

      <AnimalCard
        name="Shelly"
        gender="female"
        birthyear=""
        imageName={shelly1}
        traits=""
        acqYear="2018"
        acqNotes="Joey Mugleston, Great Basin Serpentarium"
        desc=""
      />

      <BodyText>
        Of all of our turtles Shelly seems to have had the hardest life. When I
        got her she had big chunks of her shell missing up front and it was easy
        to see why. The males in the group just brutalized her, when they
        weren't trying to kill each other.
      </BodyText>
      <BodyText>
        I set them all up in individual cages but even in recent years Shelly
        still gets pretty savagely attacked whenever I put Chili or Blaze in
        with her to see if they would be compatible breeders. Her front right
        leg has had deep bite marks in it and she's been taken to the vet for
        it. She also had a strange lump in between her front leg and her neck
        when I got her so she went to the vet for that too. Basically Shelly is
        here to make sure I know where all of the local vets are located.
      </BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Shelly;
