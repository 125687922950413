import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import sunset1 from "img/leopardgeckos/20190827-DSC_7295.jpg";
import sunset3 from "img/leopardgeckos/2019-01-13 09.39.07.jpg";
import sunset4 from "img/leopardgeckos/2019-01-13 09.40.13.jpg";
import sunset5 from "img/leopardgeckos/2019-01-13 09.40.23.jpg";
import sunset6 from "img/leopardgeckos/20190827-DSC_7294.jpg";

const imageData = [
  { img: sunset3, caption: "2019, Sunset" },
  { img: sunset4, caption: "2019, Sunset" },
  { img: sunset5, caption: "2019, Sunset" },
  { img: sunset6, caption: "2019, Sunset" },
];

const Sunset = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Sunset</title>
        <meta
          name="description"
          content="See the Leopard Geckos (Eublepharis macularius)
            that are being kept here and learn more about their captive husbandry."
        />
        <link
          rel="canonical"
          href="https://www.gcreptiles.com/sunset"
        />
      </Helmet>
      <HeaderText title="Sunset" subtitle="Leopard Gecko" />

      <AnimalCard
        name="Sunset"
        gender="male"
        birthyear="2017"
        imageName={sunset1}
        traits="Het Albino, Poss Giant"
        acqYear=""
        acqNotes=""
        desc=""
      />

      <BodyText>Sunset is our gentle giant of a leopard gecko!</BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Sunset;
