import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import whopper1 from "img/hognoses/20221110-DSC_7317.jpg";
import whopper2 from "img/hognoses/20210701-DSC_1758.jpg";
import whopper4 from "img/hognoses/20210828-DSC_3010.jpg";
import whopper6 from "img/hognoses/20210828-DSC_3070.jpg";
import whopper8 from "img/hognoses/20230125-DSC_7648.jpg";

const imageData = [
  { img: whopper2, caption: "2021, Whopper" },
  { img: whopper6, caption: "2021, Whopper" },
  { img: whopper4, caption: "2021, Whopper" },
  { img: whopper8, caption: "2023, Whopper" },
];

const Whopper = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Whopper</title>
        <meta
          name="description"
          content="See the Plains Hognose Snakes (Heterodon nasicus)
            that are being kept here and learn more about their captive husbandry."
        />
        <link
          rel="canonical"
          href="https://www.gcreptiles.com/whopper"
        />
      </Helmet>
      <HeaderText title="Whopper" subtitle="Plains Hognose Snake" />

      <AnimalCard
        name="Whopper"
        gender="male"
        birthyear="2020"
        imageName={whopper1}
        traits="Albino"
        acqYear="2020"
        acqNotes="Joey Mugleston, Great Basin Serpentarium"
        desc=""
      />

      <BodyText>
        Whopper gets his name from... well, let's just say we stopped for lunch
        on our way over to pick him up and drew inspiration from the food.
        Despite his comical look he doesn't always want to be picked up and will
        sometimes flatten his neck and hiss for a momment when we pick him up.
        But we call his bluff and grab him and he stops the show pretty quickly.
      </BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Whopper;
