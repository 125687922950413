import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";

import blizzardImg from "img/boaconstrictors/20210910-DSC_3455.jpg";
import blizzard1 from "img/boaconstrictors/20210910-DSC_3481.jpg";
import blizzard2 from "img/boaconstrictors/20210910-DSC_3558.jpg";
import Anchor from "components/anchor";
import blizzard3 from "img/boaconstrictors/20240209-DSC_5417.jpg";

const imageData = [
  { img: blizzard2, caption: "Blizzard 2021" },
  { img: blizzard1, caption: "Blizzard head shot 2021" },
  { img: blizzard3, caption: "Blizzard 2023, ovulating" },
];

const morphMarketCalculatorUrl =
  "https://www.morphmarket.com/c/reptiles/boas/boa-constrictors/genetic-calculator/" +
  "?s1=Albino+%28Sharp%29%2CHypo%2CHet+Anerythristic+%28Type+1%29&s2=Albino+%28Sharp%29%2CAnerythristic+%28Type+1%29";

const Blizzard = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Blizzard</title>
        <meta
          name="description"
          content="See the Boa Constrictors (Boa imperator)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/blizzard" />
      </Helmet>
      <HeaderText title="Blizzard" subtitle="Boa Constrictor" />

      <AnimalCard
        name="Blizzard"
        gender="female"
        birthyear="2018"
        imageName={blizzardImg}
        traits='Sharp Albino, Anery ("Snow")'
        acqYear="2019"
        acqNotes="TJ Blevins, Second City Constrictors"
        desc=""
      />

      <BodyText>
        I've been trying to think of the right way to describe Blizzard and I
        just don't have the words. She's simply stunning. The white scales are
        perfectly white and when she's outside she absolutely glows. She's super
        gentle too so I love getting her out.
      </BodyText>

      <BodyText>
        I got her with the intention of breeding her with Crush to produce more
        snakes like the two of them, but also because I love the way these adult
        Snow boas look.
      </BodyText>

      <BodyText>
        Here's the genetic breakdown of potential offspring for Crush x Blizzard
        according to the{" "}
        <Anchor href={morphMarketCalculatorUrl}>
          MorphMarket boa genetics calculator:
        </Anchor>
        <ul>
          <li>25% Hypo Albino Anery ("Snow Glow")</li>
          <li>25% Albino Anery ("Snow")</li>
          <li>25% Hypo Albino Het Anery ("Sunglow Het Anery")</li>
          <li>25% Albino Het Anery</li>
        </ul>
        Obviously a Snow Glow would be fascinating to produce and see in real
        life but to me even Sharp albinos would be awesome to make.
      </BodyText>
      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Blizzard;
