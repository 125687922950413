import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";
import { useMediaQuery } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { blue, pink } from "@mui/material/colors";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";

const AnimalCard = ({
  name,
  gender,
  birthyear,
  imageName,
  traits,
  acqYear,
  acqNotes,
  desc,
}) => {
  const mediumScreenWidth = useMediaQuery("(min-width:480px");
  const wideScreenWidth = useMediaQuery("(min-width:650px)");

  const isMale = gender ? gender.toLowerCase() === "male" : null;
  const isFemale = gender ? gender.toLowerCase() === "female" : null;

  return (
    <Card
      sx={{
        width: wideScreenWidth ? 600 : mediumScreenWidth ? 300 : 300,
        height: "auto",
        margin: 1.5,
      }}
    >
      {/**** Image ****/}
      <CardMedia
        component="img"
        height={wideScreenWidth ? "360" : "107"}
        image={imageName}
        alt={name}
      />

      <CardContent>
        {/**** Name ****/}
        <Typography
          variant={wideScreenWidth ? "h4" : "h5"}
          component="div"
          color="#423B22"
        >
          {name}
        </Typography>

        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          {/**** Gender Icon ****/}
          {isMale && <MaleIcon sx={{ color: blue[500], paddingRight: 1 }} />}
          {isFemale && (
            <FemaleIcon sx={{ color: pink[300], paddingRight: 1 }} />
          )}

          {/**** Birth Year ****/}
          <Typography
            component="span"
            variant={mediumScreenWidth ? "body1" : "body2"}
            color="#846D44"
          >
            {birthyear}
          </Typography>
        </Box>

        {/****  Traits ****/}
        <Typography
          variant={wideScreenWidth ? "h6" : "h6"}
          component="div"
          color="#423B22"
          fontWeight="light"
          fontStyle="italic"
        >
          {traits}
        </Typography>

        <Table size="small" sx={{ my: 1 }} >
          {acqYear && (
            <TableRow sx={{ borderBottomStyle: "hidden" }}>
              <TableCell>
                <Typography variant="h6" align="right">
                  Acquired
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" fontWeight="400">
                  {acqYear}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {acqNotes && (
            <TableRow  sx={{ borderBottomStyle: "hidden" }}>
              <TableCell>
                <Typography variant="h6" align="right">
                  Source
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" fontWeight="400">
                  {acqNotes}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {desc && (
            <TableRow  sx={{ borderBottomStyle: "hidden" }}>
              <TableCell>
                <Typography variant="h6" align="right">
                  Notes
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" fontWeight="400">
                  {desc}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </Table>
      </CardContent>
    </Card>
  );
};

export default AnimalCard;
