import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";

import blaze2 from "img/ebts/2018-09-10 09.07.31.jpg";
import blaze4 from "img/ebts/2021-11-01 13.26.34.jpg";
import blaze5 from "img/ebts/20201107-DSC_9228.jpg";
import blaze6 from "img/ebts/20210427-DSC_9935.jpg";
import blaze7 from "img/ebts/20210122-DSC_9578.jpg";
import blaze8 from "img/ebts/20210825-DSC_2993.jpg";
import blaze9 from "img/ebts/20211206-DSC_5117.jpg";
import blaze10 from "img/ebts/20230311-DSC_7991.jpg";
import blaze11 from "img/ebts/20230610-DSC_1226.jpg";
import blaze12 from "img/ebts/20230815-DSC_2239-Edit-Edit.jpg";
import blaze13 from "img/ebts/20230824-DSC_2279.jpg";

const imageData = [
  { img: blaze2, caption: "2018, Blaze" },
  {
    img: blaze5,
    caption: "2020, Blaze, thinking he's supposed to be arboreal",
  },
  { img: blaze4, caption: "2021, Blaze" },
  { img: blaze7, caption: "2021, Blaze, always the attention-seeker" },
  { img: blaze8, caption: "2021, Blaze, lunch-time" },
  { img: blaze9, caption: "2021, Blaze, more food" },
  { img: blaze10, caption: "2023, Blaze" },
  { img: blaze11, caption: "2023, Blaze" },
  { img: blaze12, caption: "2023, Blaze" },
  { img: blaze13, caption: "2023, Blaze" },
];

const Blaze = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Blaze</title>
        <meta
          name="description"
          content="See the Eastern Box Turtles (Terrapene carolina carolina)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/blaze" />
      </Helmet>
      <HeaderText title="Blaze" subtitle="Eastern Box Turtle" />

      <AnimalCard
        name="Blaze"
        gender="male"
        birthyear=""
        imageName={blaze6}
        traits=""
        acqYear="2018"
        acqNotes="Joey Mugleston, Great Basin Serpentarium"
        desc=""
      />

      <BodyText></BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Blaze;
