import { Helmet } from "react-helmet";
import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";

// Images
import grayband1 from "img/graybands/DSC_5008-2.jpg";
import grayband2 from "img/graybands/2019-01-09 21.14.34.jpg";
import grayband6 from "img/graybands/20210910-DSC_3444.jpg";

const albumData = [
  { img: grayband1, caption: "2018, 16 Lasers" },
  { img: grayband2, caption: "2019, 16 Lasers" },
];

const animalGridData = [
  {
    id: "16lasers",
    name: "16 Lasers",
    gender: "male",
    birthyear: "2016",
    img: grayband6,
    traits: "",
  },
];

const GrayBandedKingsnake = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Gray-banded Kingsnakes</title>
        <meta
          name="description"
          content="See the Gray-banded Kingsnakes (Lampropeltis alterna)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/gray-banded-kingsnake" />
      </Helmet>
      <HeaderText
        title="Gray-banded Kingsnakes"
        subtitle="Lampropeltis alterna"
      />
      <AnimalGrid data={animalGridData} />
      <BodyText>
        I've always wanted to keep Gray-banded kingsnakes and have been
        especially interested in the Blairs phase snakes that have the wide
        orange bands around them. In the early 2000s my wife and I visited the
        home of Ric Blair where we saw maybe 100 or so of them in his
        collection. Ric said at the time that he had a compulsive desire to buy
        them up any time he saw them for sale. I completely understand.
      </BodyText>
      <BodyText>
        While I have always wanted to own them I'm not that interested in
        breeding them. Primarily because I've heard it is such a pain to get
        babies to eat and I don't have the patience for difficult eaters.
      </BodyText>
      <ImageGrid imageData={albumData} maxWidth="900" />
    </ContentBox>
  );
};

export default GrayBandedKingsnake;
