import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import gopher1 from "img/gbgopher/20210202-DSC_9648.jpg";
import gopher4 from "img/gbgopher/DSC_6037-2.jpg";
import gopher5 from "img/gbgopher/20211003-DSC_4670.jpg";
import gopher6 from "img/gbgopher/20210922-DSC_4158.jpg";
import gopher7 from "img/gbgopher/20230304-DSC_7793.jpg";
import gopher8 from "img/gbgopher/20230601-DSC_0865-Edit.jpg";

const imageData = [
  { img: gopher4, caption: "2018, Grit" },
  { img: gopher6, caption: "2021, Grit" },
  { img: gopher1, caption: "2021, Grit" },
  { img: gopher7, caption: "2023, Grit" },
  { img: gopher8, caption: "2023, Grit" },
];

const Grit = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Grit</title>
        <meta
          name="description"
          content="See the Great Basin Gopher Snakes (Pituophis catenifer deserticola)
            that are being kept here and learn more about their captive husbandry."
        />
        <link
          rel="canonical"
          href="https://www.gcreptiles.com/grit"
        />
      </Helmet>
      <HeaderText title="Grit" subtitle="Great Basin Gopher Snake" />

      <AnimalCard
        name="Grit"
        gender="male"
        birthyear=""
        imageName={gopher5}
        traits=""
        acqYear="2016"
        acqNotes="Wild-caught as a sub-adult"
        desc=""
      />

      <BodyText></BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Grit;
