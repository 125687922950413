import { Helmet } from "react-helmet";
import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import Image from "components/image";
import ImageGrid from "components/imagegrid";

import applegatehomepage from "img/azmtnkings/applegate_reptiles_2004_09_01.png";
import applegatepricelist from "img/azmtnkings/applegate_reptiles_2004_09_01_price_list.png";
import applegatepyro from "img/azmtnkings/applegate_reptiles_2004_09_01-applpyro.jpg";
import brianHubbsBookCover from "img/azmtnkings/Brian Hubbs Mountain Kings.jpg";
import milksnake1 from "img/azmtnkings/DSCN2567.jpg";
import milksnake2 from "img/azmtnkings/DSCN2563.jpg";
import piuteCountyUtah1 from "img/azmtnkings/Img_2012-05-31_8618.jpg";

import coconutImg from "img/azmtnkings/20221024-DSC_7215.jpg";

const applegateData = [
  {
    img: applegatepyro,
    caption:
      "I absolutely drooled over this animal on Bob's website. I still do ha!",
  },
  {
    img: applegatehomepage,
    caption: "Bob Applegate's home page in 2004",
  },
  {
    img: applegatepricelist,
    caption: "June 20, 2004 price list for Applegate Reptiles",
  },
];

const animalGridData = [
  {
    id: "coconut",
    name: "Coconut",
    gender: "Female",
    birthyear: "2020",
    img: coconutImg,
  },
];

const AzMtnKingsnake = () => {
  const brianHubbsBookTitle = `Mountain Kings, A Collective Natural History of California, Sonoran, Durango, 
    and Quertaro Mountain Kingsnakes, by Brian Hubbs, 2004`;

  return (
    <ContentBox>
      <Helmet>
        <title>Arizona Mountain Kingsnakes</title>
        <meta
          name="description"
          content="See the Arizona Mountain Kingsnakes (Lampropeltis pyromelana pyromelana)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/arizona-mountain-kingsnakes" />
      </Helmet>

      <HeaderText
        title="Arizona Mountain Kingsnakes"
        subtitle="Lampropeltis pyromelana pyromelana"
      />

      <AnimalGrid data={animalGridData} />

      <BodyText>
        My introduction to Arizona Mountain kingsnakes came through websites and
        magazines back in the 90's. At the time, there were breeders like Bob
        Applegate who seemed to breed every kind of tricolor snake imaginable -
        Thayer's kingsnakes, Durango Mtn kingsnakes, Mexican milk snakes,
        Pueblan milk snakes, Sinaloan milk snakes, Nelson's milk snakes, etc. I
        was especially interested in the "Applegate pyro" which is a
        hypomelanistic AZ mountain king that is essentially a red and white
        banded snake.
      </BodyText>

      <ImageGrid imageData={applegateData} maxWidth="900" />

      <BodyText>
        Living in Utah there is a subspecies of the Arizona Mountain kingsnake
        called the Utah Mountain kingsnake (Lampropeltis p. infralabialis) but
        I've never seen one in the wild. I have encountered the cousin species -
        the Utah Milksnake (Lampropeltis triangulum taylori) - a couple of
        times, but never the mountain king. In the 2010s, the state used to
        issue this kind of permit that allowed you to hunt for them in a
        specific county and you could keep one if you found one. I had Brian
        Hubbs' "Mountain Kings" book and I studied it to try and guess where to
        go. I chose Piute county and spent 5 days and nights in late Spring and
        early Summer searching but never found one.
      </BodyText>

      <Image
        src={brianHubbsBookCover}
        caption={brianHubbsBookTitle}
        width="231"
        clickable="false"
      />

      <Image src={piuteCountyUtah1} caption="Piute County, Utah" />

      <BodyText>
        We have found a couple of Utah <i>milk</i> snakes in Salt Lake and Utah
        counties. I don't know if this is always accurate but the way that I
        differentiate the milk snake from the kingsnake is that on the milk
        snake as the white bands get closer to the ventral (bottom) side of the
        body they tend to widen out, like milk spilling on the ground.
      </BodyText>

      <Image
        src={milksnake1}
        caption="Utah Milk snake, Salt Lake County, Utah"
      />

      <Image
        src={milksnake2}
        caption="Utah Milk snake, Salt Lake County, Utah"
      />
    </ContentBox>
  );
};

export default AzMtnKingsnake;
