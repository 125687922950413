import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import cruelty1 from "img/ckrs/20220604-DSC_6666.jpg";
import cruelty2 from "img/ckrs/20230316-DSC_8040.jpg";
import cruelty3 from "img/ckrs/20230503-DSC_9314.jpg";
import cruelty4 from "img/ckrs/20230813-DSC_2222.jpg";

const imageData = [
  { img: cruelty2, caption: "2023, Cruelty" },
  { img: cruelty3, caption: "2023, Cruelty" },
  { img: cruelty4, caption: "2023, Cruelty" },
];

const Cruelty = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Cruelty</title>
        <meta
          name="description"
          content="See the Chinese King Ratsnakes (Elaphe carinata)
            that are being kept here and learn more about their captive husbandry."
        />
        <link
          rel="canonical"
          href="https://www.gcreptiles.com/cruelty"
        />
      </Helmet>
      <HeaderText title="Cruelty" subtitle="Chinese King Ratsnake" />

      <AnimalCard
        name="Cruelty"
        gender="male"
        birthyear="2020"
        imageName={cruelty1}
        traits=""
        acqYear="2022"
        acqNotes="Joshua Buchwald, Cold Blooded Kingdom"
        desc=""
      />

      <BodyText>
        Malice got her name because I love the word so much but it took a while
        for me to come up with the name for Cruelty. Then one day I was watching
        The Lord of the Rings and it came to me:
      </BodyText>

      <BodyText>
        <i>
          In the land of Mordor ... the Dark Lord Sauron forged in secret a
          master Ring ... And into this Ring he poured his<b> cruelty</b>, his
          <b> malice </b>and his will to dominate all life.{" "}
        </i>
      </BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Cruelty;
