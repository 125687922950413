import Typography from "@mui/material/Typography";
import styled from 'styled-components';

const StyledTypography = styled(Typography)`
  margin: 1em auto;
`;

const BodyText = ({ children }) => {
  return (
    <StyledTypography variant="body1">
      {children}
    </StyledTypography>
  );
};

export default BodyText;
