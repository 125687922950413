import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";

import alien1 from "img/ballpythons/DSC_6015-4.jpg";
import alien2 from "img/ballpythons/2018-02-03 14.09.16-1-2.jpg";
import alien3 from "img/ballpythons/DSC_6013-3.jpg";
import alien4 from "img/ballpythons/2019-06-30 13.09.58.jpg";
import alien5 from "img/ballpythons/2021-04-24 14.39.22.jpg";
import alien6 from "img/ballpythons/20210821-DSC_2962.jpg";
import alien7 from "img/ballpythons/20210821-DSC_2941.jpg";
import alien8 from "img/ballpythons/20210821-DSC_2945.jpg";
import alien9 from "img/ballpythons/20230217-DSC_7708.jpg";

const Alien = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Alien</title>
        <meta
          name="description"
          content="See the Ball Pythons (Python regius)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/alien" />
      </Helmet>
      <HeaderText title="Alien" subtitle="Ball Python" />

      <AnimalCard
        name="Alien"
        gender="male"
        birthyear="2015"
        imageName={alien1}
        traits="Albino, Black Pastel"
        acqYear="2016"
        acqNotes="Vesper Ball Pythons"
        desc=""
      />

      <BodyText>
        Alien gets his name from the "alien head" pattern on his sides.
      </BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Alien;

const imageData = [
  { img: alien2, caption: "At 3 years old" },
  { img: alien3, caption: "" },
  {
    img: alien4,
    caption:
      "I had no idea Firefly was gravid so Alien was still paired with her when she laid eggs",
  },
  { img: alien5, caption: "2021" },
  { img: alien6, caption: "2021 shots in the yard" },
  { img: alien7, caption: "" },
  { img: alien8, caption: "" },
  { img: alien9, caption: "2023" },
];
