import { Helmet } from "react-helmet";
import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";

import crushImg from "img/boaconstrictors/20210831-DSC_3297.jpg";
import blizzardImg from "img/boaconstrictors/20210910-DSC_3455.jpg";
import femRcPastelImg from "img/boaconstrictors/20221024-DSC_7206.jpg";
import maleRcPastelImg from "img/boaconstrictors/20221028172710.681-Male10a-1500x1500s.jpg";

import blizzard1 from "img/boaconstrictors/20210910-DSC_3462.jpg";
import crush1 from "img/boaconstrictors/20190505-DSC_6876.jpg";
import crush2 from "img/boaconstrictors/20210831-DSC_3212.jpg";
import vpiFemale1 from "img/boaconstrictors/2022 RC Pastel VPI T+ Albino Female num13 - original.jpg";
import vpiFemale2 from "img/boaconstrictors/20230204-DSC_7679.jpg";

const albumData = [
  { img: crush1, caption: "Crush in 2019" },
  { img: blizzard1, caption: "Blizzard in 2021" },
  { img: crush2, caption: "Crush in 2021" },
  { img: vpiFemale1, caption: "RC Pastel Jungle female, pic from breeder in 2022" },
  { img: vpiFemale2, caption: "RC Pastel female in 2023" },
];

const animalGridData = [
  {
    id: "crush",
    name: "Crush",
    gender: "male",
    birthyear: "2017",
    img: crushImg,
    traits: 'Sharp Albino, Hypo, het Anery ("Sunglow het Anery")',
  },
  {
    id: "blizzard",
    name: "Blizzard",
    gender: "female",
    birthyear: "2018",
    img: blizzardImg,
    traits: 'Sharp Albino, Anery ("Snow")',
  },
  {
    id: "rcpastelmale",
    name: "",
    gender: "male",
    birthyear: "2022",
    img: maleRcPastelImg,
    traits: "RC Pastel, VPI T+ Albino, Jungle",
  },
  {
    id: "rcpastelfemale",
    name: "",
    gender: "female",
    birthyear: "2022",
    img: femRcPastelImg,
    traits: "RC Pastel, VPI T+ Albino",
  },
];

const BoaConstrictor = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Boa Constrictors</title>
        <meta
          name="description"
          content="See the Boa Constrictors (Boa imperator)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/boa-constrictors" />
      </Helmet>

      <HeaderText title="Boa Constrictors" subtitle="Boa imperator" />

      <AnimalGrid data={animalGridData} />

      <BodyText>
        I've always wanted boa constrictors but to be honest I wasn't aware of
        the difference between <i>boa imperator</i> and <i>boa constrictor</i>{" "}
        until the late 90's (and back then the taxonomy was different). So the
        books and magazine articles I had before then talked about "boa
        constrictors" that could potentially reach 15 feet long and that is just
        too big for me.
      </BodyText>

      <BodyText>
        But then this thing called the Internet came around and taught me that
        these boas are two different species and so I've been watching them more
        closely since then. I have <i>boa imperators</i> currently but I stare
        at the <i>boa constrictors</i> - particularly the ones from Peru - on a
        regular basis.
      </BodyText>

      <BodyText>
        I started with a pair of Sharp albinos - Crush and Blizzard - but I also
        love the VPI T+ Albino gene so I have a pair of boas sporting those
        traits as well. I want to say that's enough boas but man, have you seen
        an VPI T+ with IMG and Aztec in it? Sooooooo hot.
      </BodyText>

      <ImageGrid imageData={albumData} maxWidth="900" />
    </ContentBox>
  );
};

export default BoaConstrictor;
