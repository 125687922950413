import { Helmet } from "react-helmet";
import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";

// Dr Pepper
import pepper1 from "img/papuancarpets/20221008-DSC_7097.jpg";
import pepper2 from "img/papuancarpets/20221102-DSC_7247.jpg";
import pepper5 from "img/papuancarpets/20210701-DSC_1747.jpg";

// Mello Yello
import mello1 from "img/papuancarpets/20210128-DSC_9595.jpg";
import mello2 from "img/papuancarpets/20221008-DSC_7155.jpg";

const albumData = [
  { img: pepper5, caption: "2021, Dr Pepper" },
  { img: pepper2, caption: "2022, Dr Pepper" },
  { img: mello2, caption: "2022, Mello Yello" },
];

const animalGridData = [
  {
    id: "drpepper",
    name: "Dr Pepper",
    gender: "male",
    birthyear: "2020",
    img: pepper1,
    traits: "",
  },
  {
    id: "mello",
    name: "Mello Yello",
    gender: "female",
    birthyear: "2020",
    img: mello1,
    traits: "",
  },
];

const PapuanCarpetPython = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Papuan Carpet Pythons</title>
        <meta
          name="description"
          content="See the Papuan Carpet Pythons (Morelia spilota variegata)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/papuan-carpet-python" />
      </Helmet>
      <HeaderText
        title="Papuan Carpet Pythons"
        subtitle="Morelia spilota variegata"
      />
      <AnimalGrid data={animalGridData} />
      <BodyText>
        My Papuan carpet pythons come from Inland Reptiles and are a pretty
        recent addition for me, even though I've seen wild-caught Papuans
        available from importers for decades. They are frequently overlooked,
        but incredible to look at. Mine stay perched on their branches nearly
        full-time.
      </BodyText>
      <ImageGrid imageData={albumData} maxWidth="900" />
    </ContentBox>
  );
};

export default PapuanCarpetPython;
