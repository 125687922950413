import { Helmet } from "react-helmet";
import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import Image from "components/image";
import ImageGrid from "components/imagegrid";

// Images for animals in the collection grid
import femaleBrbImg from "img/brbs/20230124-DSC_7644.jpg";

// Bulk image album
import femBrb1 from "img/brbs/20230124-DSC_7643.jpg";
import archiveBrb1 from "img/brbs/BRB002a.jpg";
import archiveBrb2 from "img/brbs/BRB004a.jpg";
import archiveBrb3 from "img/brbs/BRB008a.jpg";
import archiveBrb4 from "img/brbs/20181229-scan405-3.jpg";
import archiveFemBrb1 from "img/brbs/DSCN0061a.JPG";
import archiveFemBrb2 from "img/brbs/Scans 003.jpg";
import archiveMaleBrb1 from "img/brbs/BRB_Male002.jpg";
import archiveMaleBrb2 from "img/brbs/Herps 033.jpg";

const animalGridData = [
  {
    id: "iris",
    name: "Iris",
    gender: "female",
    birthyear: "2022",
    img: femaleBrbImg,
    traits: "",
  },
];

const albumData = [
  {
    img: archiveFemBrb1,
    caption: "2001, original female Brazilian Rainbow boa",
  },
  { img: archiveMaleBrb2, caption: "2003, male Brazilian Rainbow boa" },
  { img: archiveBrb1, caption: "2003, son with adult Brazilian Rainbow boas" },
  { img: archiveBrb3, caption: "2003, son with adult Brazilian Rainbow boas" },
  { img: archiveFemBrb2, caption: "late 2000s, female Brazilian Rainbow boa" },
  {
    img: archiveMaleBrb1,
    caption: "late 2000s, male Brazilian Rainbow boa eating",
  },
  { img: femBrb1, caption: "2023, new female Brazilian Rainbow boa" },
];

const BrazilianRainbowBoa = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Brazilian Rainbow Boas</title>
        <meta
          name="description"
          content="See the Brazilian Rainbow Boas (Epicrates cenchria)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/brazilian-rainbow-boas" />
      </Helmet>

      <HeaderText
        title="Brazilian Rainbow Boas"
        subtitle="Epicrates cenchria"
      />

      <AnimalGrid data={animalGridData} />

      <BodyText>
        I got my first Brazilian Rainbow boa in the mid-90s as a baby from an ad
        someone had posted in the back of Reptiles magazine. I wish I could
        remember who the person was. The little female snake did awesome for us
        and grew up with lots of people handling her and she never showed the
        slightest sign of discontent. Years later when I heard people say that
        BRBs were very bitey I couldn't believe it, but of course I had a small
        sample size of experience.
      </BodyText>
      <Image
        src={archiveBrb4}
        caption="1997, my first Brazilian Rainbow boa"
      />
      <BodyText>
        Sometime in the early 2000's I got a second BRB, a male, from someone
        locally here in Utah but again, it has been too long ago for me to
        remember who. The male had a lighter orange to it and was equally tame
        and handleable. At the time there was a certain look to lighter colored
        BRBs in the hobby that was referred to as "Lamar's phase" and there was
        some thought that the male was of that type/phase/variety/whatever. I
        never found out for sure.
      </BodyText>
      <Image
        src={archiveBrb2}
        caption="2003, Comparison of both Brazilian Rainbow boas"
      />
      <BodyText>
        I had hopes to breed them but there was a big setback for me when the
        female was free-roaming the room and got stuck trying to get under the
        door. I took the door off the hinges to get her free but she had damaged
        her spine in the process and was permanently disfigured from that. A
        local vet said that surgery was the only option and that the odds of her
        recovering completely were only about 50/50. So I didn't have that done.
        Given her condition I didn't dare try and breed her. Still, she lived
        for many years after that.
      </BodyText>
      <BodyText>
        I lost both BRBs in the early 2010s. Living in Utah it is extremely dry
        and the relative humidity is usually about 28-30%. This is way too dry
        for BRBs so I was misting them frequently. However, I was keeping them
        in glass-front Vision cages and using newspaper as substrate and that
        just wasn't a good setup for maintaining high enough humidity for the
        animals to thrive so both of them died of respiratory problems.
      </BodyText>
      <BodyText>
        I wasn't going to try high-humidity animals again in my home but as the
        years have gone on and I've spent more time getting better equipment,
        cages, and methods, I decided I was ready to try BRBs again so in early
        2023 I bought a baby female. Almost 25 years after the first one.
      </BodyText>

      <ImageGrid imageData={albumData} maxWidth="900" />
    </ContentBox>
  );
};

export default BrazilianRainbowBoa;
