import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import bear1 from "img/dumerils/20210202-DSC_9621.jpg";
import bear2 from "img/dumerils/20201114-DSC_9385.jpg";
import bear4 from "img/dumerils/20210828-DSC_3149.jpg";

const imageData = [
  { img: bear2, caption: "2020, Bear" },
  { img: bear1, caption: "2021, Bear" },
];

const Bear = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Bear</title>
        <meta
          name="description"
          content="See the Dumeril's Boas (Acrantophis dumerili)
            that are being kept here and learn more about their captive husbandry."
        />
        <link
          rel="canonical"
          href="https://www.gcreptiles.com/bear"
        />
      </Helmet>
      <HeaderText title="Bear" subtitle="Dumeril's Boa" />

      <AnimalCard
        name="Bear"
        gender="male"
        birthyear="2018"
        imageName={bear4}
        traits=""
        acqYear="2018"
        acqNotes="self-produced"
        desc=""
      />

      <BodyText></BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Bear;
