import * as React from "react";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import collectionData from "./collection-data";
import CollectionCard from "components/collectioncard";

const CollectionGrid = () => {
  return (
    <>
      <Helmet>
        <title>Reptile Collection</title>
        <meta
          name="description"
          content="From here you can navigate to any of the species of pythons, boas, or colubrids in the collection"
        />
      </Helmet>
      <Box
      sx={{
        margin: 2,
        display: "inline-flex",
        flexWrap: "wrap",
      }}
    >
      {collectionData.map((item) => (
        <CollectionCard
          key={item.id}
          id={item.id}
          title={item.species}
          smImg={item.smImg}
          mdImg={item.mdImg}
          sciName={item.sciName}
        />
      ))}
    </Box>
    </>
  );
};

export default CollectionGrid;
