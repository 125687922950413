import { Helmet } from "react-helmet";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
// import Image from "components/image";

const Contact = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Contact GC Reptiles</title>
        <meta
          name="description"
          content="For feedback or more information contact me here."
        />
      </Helmet>
      {/* <Image src={morphmarketlogo} clickable="false" /> */}
      <BodyText>
        For feedback or more information please contact me at{" "}
        <a href="mailto:gregcooper669@gmail.com" alt="Mail gregcooper669@gmail.com" target="_top">
          gregcooper669@gmail.com
        </a>
      </BodyText>
    </ContentBox>
  );
};

export default Contact;
