import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import Image from "components/image";
import ImageGrid from "components/imagegrid";

import azking1 from "img/azmtnkings/2020-10-03 16.11.17.jpg";
import azking2 from "img/azmtnkings/DSC_9154.jpg";
import azking3 from "img/azmtnkings/20210427-DSC_9966.jpg";
import azking4 from "img/azmtnkings/20220723-DSC_6818.jpg";
import azking5 from "img/azmtnkings/20220723-DSC_6826.jpg";
import azking6 from "img/azmtnkings/20221024-DSC_7215.jpg";
import azking7 from "img/azmtnkings/20230814-DSC_2233-Edit.jpg";
import azking8 from "img/azmtnkings/2023-03-11 17.53.29.jpg";

const Coconut = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Coconut</title>
        <meta
          name="description"
          content="See the Arizona Mountain Kingsnakes (Lampropeltis pyromelana pyromelana)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/coconut" />
      </Helmet>
      <HeaderText title="Coconut" subtitle="Arizona Mountain Kingsnake" />

      <AnimalCard
        name="Coconut"
        gender="female"
        birthyear="2020"
        imageName={azking6}
        traits=""
        acqYear="2020"
        acqNotes="Joel Reep, Prismatic Reptiles, Wasatch Reptile Expo"
        desc="Badly kinked tail"
      />

      <BodyText>
        I've always wanted to buy captive-bred Arizona Mountain kingsnakes and
        raise them for breeding but I don't know if I have the patience to deal
        with finicky baby snakes that won't eat. However, at the Fall 2020
        Wasatch Reptile Expo I was talking to Joel Reep at the Prismatic
        Reptiles table and my son spotted an AZ mountain king that Joel had on
        the table for $25. We looked at it and saw that it had a badly kinked
        spine. I wasn't exactly looking for an animal like that but my son was
        excited about it so we bought it and named her Coconut.
      </BodyText>

      <Image src={azking1} caption="Oct 2020, soon after we purchased her" />

      <BodyText>
        Coconut has done really well for us as far as eating and general care
        goes so she doesn't seem hindered by her deformity. She has bitten once
        or twice and she can discharge feces at will, but for the most part she
        handles fine if you hold your hands flat and let her crawl around on her
        own terms without constraining her or squeezing.
      </BodyText>

      <BodyText>
        Given her physical condition there is no chance we will try breeding
        her. I would hate to find out that the kinks in her spine don't allow
        eggs to pass and she ended up egg-bound and dying. But the small size of
        this species would make it easy to make room for a pair so maybe
        Applegate pyros will happen for us sometime.
      </BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Coconut;

const imageData = [
  {
    img: azking2,
    caption: "Oct 2020, first time in the lightbox",
  },
  {
    img: azking3,
    caption: "Apr 2021",
  },
  {
    img: azking4,
    caption: "Jul 2022, her kinked spine is very obvious here",
  },
  {
    img: azking5,
    caption: "Jul 2022, at two years old",
  },
  {
    img: azking6,
    caption: "Oct 2022",
  },
  {
    img: azking7,
    caption: "Mar 2023",
  },
  {
    img: azking8,
    caption: "Aug 2023",
  },
];
