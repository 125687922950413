import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";

import egypt1 from "img/ebts/20210427-DSC_9953.jpg";
import egypt2 from "img/ebts/20190517-DSC_7045.jpg";
import egypt3 from "img/ebts/20210828-DSC_3179.jpg";
import egypt4 from "img/ebts/20210427-DSC_9937.jpg";
import egypt6 from "img/ebts/2021-08-28 17.58.12.jpg";
import egypt7 from "img/ebts/20230311-DSC_8001.jpg";
import egypt8 from "img/ebts/20230311-DSC_7973.jpg";
import egypt9 from "img/ebts/20230610-DSC_1286.jpg";
import egypt10 from "img/ebts/20230607-DSC_1061.jpg";

const imageData = [
  { img: egypt2, caption: "2019, Egypt" },
  { img: egypt3, caption: "2021, Egypt" },
  { img: egypt4, caption: "2021, Egypt" },
  { img: egypt6, caption: "2021, Egypt" },
  { img: egypt7, caption: "2023, Egypt" },
  { img: egypt8, caption: "2023, Egypt" },
  { img: egypt9, caption: "2023, Egypt" },
  { img: egypt10, caption: "2023, Egypt" },
];

const Egypt = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Egypt</title>
        <meta
          name="description"
          content="See the Eastern Box Turtles (Terrapene carolina carolina)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/egypt" />
      </Helmet>
      <HeaderText title="Egypt" subtitle="Eastern Box Turtle" />

      <AnimalCard
        name="Egypt"
        gender="female"
        birthyear=""
        imageName={egypt1}
        traits=""
        acqYear=""
        acqNotes=""
        desc=""
      />

      <BodyText></BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Egypt;
