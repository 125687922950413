import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const HeaderText = ({ title, subtitle, children }) => {

  // If you need a `subtitle`, then pass in `title` and `subtitle`.
  // Either way, anything between the brackets will be treated as `title`.

  return (
    <Box sx={{ mb: 2, mx: "auto", maxWidth: 600, textAlign: "center" }}>
      <Typography variant="h4">{title}</Typography>
      <Typography variant="h6">
        <Box sx={{ fontStyle: "italic", fontWeight: 200 }}>{subtitle}</Box>
      </Typography>
      <Typography variant="h4">{children}</Typography>
    </Box>
  );
};

export default HeaderText;
