import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";

import cheesecake1 from "img/ballpythons/20221102-DSC_7275.jpg";
import cheesecake2 from "img/ballpythons/2017-08-30 09.07.49-2.jpg";
import cheesecake3 from "img/ballpythons/DSC_6009-1.jpg";
import cheesecake4 from "img/ballpythons/2018-09-09 11.44.08-2.jpg";
import cheesecake5 from "img/ballpythons/20191118-DSC_7851.jpg";
import cheesecake6 from "img/ballpythons/20191212-DSC_8106.jpg";
import cheesecake7 from "img/ballpythons/20191212-DSC_8116.jpg";
import cheesecake8 from "img/ballpythons/20220331-DSC_5828.jpg";
import cheesecake9 from "img/ballpythons/20220331-DSC_5804.jpg";
import cheesecake10 from "img/ballpythons/20230211-DSC_7702.jpg";
import cheesecake11 from "img/ballpythons/2022-11-25 14.59.43.jpg";
import cheesecake12 from "img/ballpythons/2022-11-23 11.59.45.jpg";

const Cheesecake = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Cheesecake</title>
        <meta
          name="description"
          content="See the Ball Pythons (Python regius)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/cheesecake" />
      </Helmet>
      <HeaderText title="Cheesecake" subtitle="Ball Python" />

      <AnimalCard
        name="Cheesecake"
        gender="female"
        birthyear="2014"
        imageName={cheesecake1}
        traits="Albino"
        acqYear="2015"
        acqNotes="birthday present for my son"
        desc=""
      />

      <BodyText>
        Cheesecake was bought as a 10th birthday present for my son. We have a
        funny home video where he is sitting on the couch opening presents and
        when he wasn't looking I place a balled-up yearling Cheesecake on the
        ottoman in front of him and she is so still and small my son didn't
        notice she was there for several minutes.
      </BodyText>

      <BodyText>
        I've bred Cheesecake to Alien a couple of times with the intent of
        creating albinos and albino black pastels. We lost two clutches of her
        eggs over the years before I got a home-made incubator worked out.
      </BodyText>

      <BodyText>
        In late 2022 I paired Cheesecake with Alien again and she laid another
        clutch of eggs. This time there were 8 eggs and all 8 hatched giving a
        perfect ratio of half that are normal albinos and half that are albino
        black pastels.
      </BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Cheesecake;

const imageData = [
  {
    img: cheesecake2,
    caption: "Cheesecake tail-locked with Alien in 2017",
  },
  {
    img: cheesecake3,
    caption: "Summer 2018",
  },
  {
    img: cheesecake4,
    caption:
      "Clutch from 9/2018, I was unable to incubate these eggs successfully",
  },
  {
    img: cheesecake5,
    caption: "Clutch from 11/2019",
  },
  {
    img: cheesecake6,
    caption: "Albino black pastel from 11/2019",
  },
  {
    img: cheesecake7,
    caption: "Albino from 11/2019",
  },
  {
    img: cheesecake9,
    caption: "March 2022",
  },
  {
    img: cheesecake8,
    caption: "March 2022",
  },
  {
    img: cheesecake11,
    caption: "Nov 2022, carrying a big clutch of eggs",
  },
  {
    img: cheesecake12,
    caption: "Nov 2022, pulled her light bar down to help warm her eggs haha",
  },
  {
    img: cheesecake10,
    caption: "Clutch from 2/2023",
  },
];
