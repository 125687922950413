import { Helmet } from "react-helmet";
import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";

// Bulk image album
import bacon2 from "img/centralians/20210209-DSC_9659.jpg";
import bacon4 from "img/centralians/20210701-DSC_1728.jpg";
import bacon5 from "img/centralians/20210913-DSC_3945.jpg";
import bacon6 from "img/centralians/20210913-DSC_3985.jpg";
import carbonara3 from "img/centralians/20200812-DSC_8474.jpg";
import carbonara5 from "img/centralians/20201114-DSC_9398.jpg";
import carbonara6 from "img/centralians/20201114-DSC_9402.jpg";
import archiveFem1 from "img/centralians/20190517-DSC_6965-2.jpg";
import archiveFem2 from "img/centralians/20190517-DSC_6969-2.jpg";

const albumData = [
  { img: bacon2, caption: "Feb 2021 Baconator" },
  { img: bacon4, caption: "Jul 2021 Baconator" },
  { img: bacon5, caption: "Sep 2021 Baconator" },
  { img: carbonara3, caption: "Aug 2020 Carbonara" },
  { img: carbonara5, caption: "Nov 2020 Carbonara" },
  { img: archiveFem1, caption: "2018 Dark Life" },
  { img: archiveFem2, caption: "2018 Dark Life" },
];

const animalGridData = [
  {
    id: "baconator",
    name: "Baconator",
    gender: "male",
    birthyear: "2019",
    img: bacon6,
    traits: "Hypo",
  },
  {
    id: "carbonara",
    name: "Carbonara",
    gender: "female",
    birthyear: "2018",
    img: carbonara6,
    traits: "Hypo",
  },
];

const CentralianPython = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Centralian Pythons</title>
        <meta
          name="description"
          content="See the Centralian Pythons (Morelia bredli)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/centralian-pythons" />
      </Helmet>

      <HeaderText title="Centralian Pythons" subtitle="Morelia bredli" />

      <AnimalGrid data={animalGridData} />

      <BodyText>
        Centralian, or Bredl's, pythons are relatively new for me. I have two of
        the "pure hypo" Bredl's from Nick Mutton and they are absolutely
        gorgeous animals. I had heard that Bredl's were the tamest carpet
        pythons but apparently the hypomelanistic gene that I've bought into can
        be a bit flighty. Still, I have never been bitten by one - partly
        because I can dodge their strikes so well - but they are calming down
        with time. So maybe they have to get through the nippy phase that all
        carpet pythons go through in their first two years or so.
      </BodyText>

      <BodyText>
        Mine are in their grow-up cages which have perches and are close to the
        door in and out of my reptile room so they are on display constantly and
        are amazing to see. I hope to breed them in 2024 and so I will need them
        in their bigger cages by then. During the winter of 2022/23 I brumated
        them for 3 months by placing them in a 50-55 F room at night and then
        bringing them back into the snake room and letting them sit with just
        ambient heat at 70-73 F during the day. Then I'd move them back at
        night. I intend to do this every year for them.
      </BodyText>

      <BodyText>
        I should mention that I did get a baby Bredl's python as a trade in
        2017. We named her Dark Life mainly because of the life choices she made
        while she was with us. She was definitely in her early, ugly phase but
        she was also difficult to get feeding. After about 9 months she did
        start eating for a few months, only to stop and basically request
        death-by-starvation, which was granted to her a few months later.
      </BodyText>

      <ImageGrid imageData={albumData} maxWidth="900" />
    </ContentBox>
  );
};

export default CentralianPython;
