import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import mystique1 from "img/diamonds/09-02-2020 female 7 (F7).jpg";
import mystique5 from "img/diamonds/11-04-2020 fresh shed.jpg";
import mystique3 from "img/diamonds/2020-12-13 15.15.42.jpg";
import mystique6 from "img/diamonds/20211118-DSC_5010.jpg";
import mystique4 from "img/diamonds/2021-05-03 18.14.42.jpg";
import mystique7 from "img/diamonds/20230317-DSC_8078.jpg";
import mystique8 from "img/diamonds/20230711-DSC_1593.jpg";
import mystique9 from "img/diamonds/20230711-DSC_1598.jpg";
import mystique10 from "img/diamonds/20231110-DSC_5136.jpg";

const imageData = [
  { img: mystique1, caption: "2020, Mystique, pic from breeder" },
  { img: mystique5, caption: "2020, Mystique, pic from breeder" },
  { img: mystique3, caption: "2020, Mystique" },
  { img: mystique4, caption: "2021, Mystique" },
  { img: mystique7, caption: "2023, Mystique" },
  { img: mystique8, caption: "2023, Mystique" },
  { img: mystique9, caption: "2023, Mystique" },
  { img: mystique10, caption: "2023, Mystique" },
];

const Mystique = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Mystique</title>
        <meta
          name="description"
          content="See the Diamond Pythons (Morelia spilota spilota)
            that are being kept here and learn more about their captive husbandry."
        />
        <link
          rel="canonical"
          href="https://www.gcreptiles.com/mystique"
        />
      </Helmet>
      <HeaderText title="Mystique" subtitle="Diamond Python" />

      <AnimalCard
        name="Mystique"
        gender="female"
        birthyear="2020"
        imageName={mystique6}
        traits=""
        acqYear="2020"
        acqNotes="Joshua Easter"
        desc=""
      />

      <BodyText></BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Mystique;
