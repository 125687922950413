import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import snuggles4 from "img/dumerils/20210202-DSC_9626.jpg";
import snuggles1 from "img/dumerils/F1_20200912-DSC_8651.jpg";
import snuggles2 from "img/dumerils/F1_20200912-DSC_8612.jpg";
import snuggles3 from "img/dumerils/2021-05-03 18.11.26.jpg";
import snuggles5 from "img/dumerils/F1_20200924-DSC_8905.jpg";
import snuggles6 from "img/dumerils/20230204-DSC_7686.jpg";
import snuggles7 from "img/dumerils/F1_DSC_8522.jpg";
import snuggles8 from "img/dumerils/20230714-DSC_1644.jpg";

const imageData = [
  { img: snuggles1, caption: "2020, Snuggles" },
  { img: snuggles2, caption: "2020, Snuggles" },
  { img: snuggles5, caption: "2020, Snuggles" },
  { img: snuggles7, caption: "2020, Snuggles" },
  { img: snuggles3, caption: "2021, Snuggles" },
  { img: snuggles6, caption: "2023, Snuggles" },
  { img: snuggles8, caption: "2023, Snuggles" },
];

const Snuggles = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Snuggles</title>
        <meta
          name="description"
          content="See the Dumeril's Boas (Acrantophis dumerili)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/snuggles" />
      </Helmet>
      <HeaderText title="Snuggles" subtitle="Dumeril's Boa" />

      <AnimalCard
        name="Snuggles"
        gender="female"
        birthyear="2020"
        imageName={snuggles4}
        traits=""
        acqYear="2020"
        acqNotes="self-produced"
        desc=""
      />

      <BodyText></BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Snuggles;
