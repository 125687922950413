import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
// import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";
// import Video from "components/video";

import suncka1 from "img/hondurans/2023-08-20 20.40.43.jpg";
import suncka2 from "img/hondurans/20230814-DSC_2229-Edit.jpg";

const imageData = [{ img: suncka1, caption: "2023, Suncka" }];

const Suncka = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Suncka</title>
        <meta
          name="description"
          content="See the Honduran Milksnakes (Lampropeltis triangulum hondurensis)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/suncka" />
      </Helmet>
      <HeaderText title="Suncka" subtitle="Honduran Milksnake" />

      <AnimalCard
        name="Suncka"
        gender="male"
        birthyear="2022"
        imageName={suncka2}
        traits="Het Albino, Het Hypo, 50% Het Anery"
        acqYear="2023"
        acqNotes="Chris Sharp, Sharp Shooter Reptiles, Pomona Super Show"
        desc=""
      />

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Suncka;
