import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import pepper1 from "img/papuancarpets/20221008-DSC_7097.jpg";
import pepper3 from "img/papuancarpets/20-HG-DHG-M3.jpg";
import pepper4 from "img/papuancarpets/20210128-DSC_9593.jpg";
import pepper5 from "img/papuancarpets/20230503-DSC_9322.jpg";
import pepper6 from "img/papuancarpets/20230304-DSC_7795.jpg";
import pepper7 from "img/papuancarpets/20231110-DSC_5130.jpg";

const imageData = [
  { img: pepper3, caption: "2020, Dr Pepper, pic from breeder" },
  { img: pepper4, caption: "2021, Dr Pepper" },
  { img: pepper5, caption: "2023, Dr Pepper" },
  { img: pepper6, caption: "2023, Dr Pepper" },
  { img: pepper7, caption: "2023, Dr Pepper" },
];

const DrPepper = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Dr Pepper</title>
        <meta
          name="description"
          content="See the Papuan Carpet Pythons (Morelia spilota variegata)
            that are being kept here and learn more about their captive husbandry."
        />
        <link
          rel="canonical"
          href="https://www.gcreptiles.com/drpepper"
        />
      </Helmet>
      <HeaderText title="Dr Pepper" subtitle="Papuan Carpet Python" />

      <AnimalCard
        name="Dr Pepper"
        gender="male"
        birthyear="2020"
        imageName={pepper1}
        traits=""
        acqYear="2021"
        acqNotes="Nick Mutton, Inland Reptiles"
        desc=""
      />

      <BodyText></BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default DrPepper;
