import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import asmodeus1 from "img/blackpines/20221008-DSC_7168.jpg";
import asmodeus2 from "img/blackpines/20230317-DSC_8088.jpg";

const imageData = [
  { img: asmodeus2, caption: "2023, in shed" },
];

const Asmodeus = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Asmodeus</title>
        <meta
          name="description"
          content="See the Black Pine Snakes (Pituophis melanoleucus lodingi)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/asmodeus" />
      </Helmet>

      <HeaderText title="Asmodeus" subtitle="Black Pine Snake" />

      <AnimalCard
        name="Asmodeus"
        gender="male"
        birthyear="2021"
        imageName={asmodeus1}
        traits=""
        acqYear="2022"
        acqNotes="Summer Sutherland, TSK"
        desc=""
      />

      <BodyText></BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Asmodeus;
