import Box from "@mui/material/Box";
import AnimalGridCard from "components/animalgridcard";

const AnimalGrid = ({ data }) => {
  return (
    <Box
      sx={{
        margin: 0,
        display: "inline-flex",
        flexWrap: "wrap",
      }}
    >
      {data.map((item) => (
        <AnimalGridCard
          key={item.id}
          id={item.id}
          name={item.name}
          gender={item.gender}
          birthyear={item.birthyear}
          imageName={item.img}
          traits={item.traits}
        />
      ))}
    </Box>
  );
};

export default AnimalGrid;
