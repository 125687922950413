import { Helmet } from "react-helmet";
import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";

import cruelty1 from "img/ckrs/20220604-DSC_6666.jpg";
import malice1 from "img/ckrs/20220604-DSC_6684.jpg";
import malice2 from "img/ckrs/20221008-DSC_7162.jpg";
import ratsnakesBook from "img/ckrs/TheAsianRatsnakesBook.jpg";
import dmexotics1 from "img/ckrs/dmexotics1.jpg";
import Image from "components/image";
import Anchor from "components/anchor";

const albumData = [{ img: malice2, caption: "" }];

const animalGridData = [
  {
    id: "cruelty",
    name: "Cruelty",
    gender: "male",
    birthyear: "2020",
    img: cruelty1,
    traits: "",
  },
  {
    id: "malice",
    name: "Malice",
    gender: "female",
    birthyear: "2020",
    img: malice1,
    traits: "",
  },
];

const ChineseKingRatsnake = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Chinese King Ratsnakes</title>
        <meta
          name="description"
          content="See the Chinese King Ratsnakes (Elaphe carinata)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/chinese-king-ratsnakes" />
      </Helmet>
      <HeaderText title="Chinese King Ratsnakes" subtitle="Elaphe carinata" />
      <AnimalGrid data={animalGridData} />
      <BodyText>
        For a long time I have had the urge to get at least one species of Asian
        colubrid, so I decided on "the Stinking Goddess", aka the Chinese King
        Ratsnake. I sort of blame Dan Mulleary for this. He made{" "}
        <Anchor href="https://www.youtube.com/watch?v=5Ox8oHYxI9c">
          this video
        </Anchor>{" "}
        where he is unboxing several of these and a couple of the snakes are
        especially big, beautiful, and of course, very calm. So that became my
        target species. (I ignored the fact that the animals were probably cold,
        and thus calm, from the shipping process.) Dan is great to watch by the
        way since he does so much work with Asian species.
      </BodyText>
      <Image
        src={dmexotics1}
        caption="Dan Mulleary with a large Chinese King Ratsnake"
      />
      <BodyText>
        I bought a pair of CKRs and they quickly showed me their true
        personalities. First of all they don't smell particularly bad like I was
        expecting. I mean it's not as good as the smell of baked bread, but it's
        not worse than the other stinky colubrids that I keep. The Black
        ratsnakes that I used to keep a long time ago were particularly
        foul-smelling.
      </BodyText>
      <BodyText>
        In terms of handleability the male is slightly easier to handle in that
        I don't get bitten by him every two seconds like I do when handling the
        female. The male just tries to squirm away which is not surprising. But
        at least he only bites occasionally.
      </BodyText>
      <BodyText>
        The female, who quickly earned the name Malice, doesn't stop biting when
        she's in my hands. I've tried things like getting her out once or twice
        a week consistently for a few minutes at a time to try and have some
        positive handling experiences with her so that she can learn that I'm
        not here to hurt her but so far I haven't noticed any changes in her
        behavior. When we have these handling sessions I talk out loud to her
        hoping that she becomes accustomed to my sight<i> and </i>sound. I
        sarcastically announce, "Malice, it's playtime!" and maybe she senses
        the sarcasm and it just angers her more. I don't know. Playtime has
        become her nickname though and I'm sure that she hates that name, which
        encourages me to use it often.
      </BodyText>
      <BodyText>
        Here is a great book to get to learn more about Asian ratsnakes. I got
        it and was surprised at how many species of Asian ratsnakes I wanted
        after reading it. So consider yourself warned. Books like this should
        come with a 10% off coupon that's good at breeders' online stores.
      </BodyText>
      <Image
        src={ratsnakesBook}
        caption="The Asian Ratsnakes and Kin of Greater China, by Kevin R Messenger"
        width="300"
      />
      <BodyText>
        Any ways, my Chinese King Ratsnakes are beautiful snakes and I love
        having them if for no other reason than getting that periodic adrenaline
        spike when I get them out. And when she is not being tormented by me,
        Malice will roam the plants and logs in her cage all day and poke her
        head out over the ledge and watch me as I work. It's both endearing and
        creepy to see her staring at me with those evil eyes. I<i> am </i>
        nervous about having 7- or 8-foot long death machines in my collection
        in a few years so I do hope eventually they get more tractable. For now,
        there is a lock on their cages so that no one accidentally opens their
        cages when I'm not around.
      </BodyText>
      <BodyText>
        If things go smoothly, and they usually don't, I'll be trying to breed
        them in 2025. And if I get any babies that are even remotely more calm
        than their parents I will hold onto them and grow them up and who knows?
        Malice and Cruelty might be on MorphMarket one day and their babies will
        live here instead. It would be like me sending VHS copies of The Ring to
        a stranger...
      </BodyText>
      <ImageGrid imageData={albumData} maxWidth="900" />
    </ContentBox>
  );
};

export default ChineseKingRatsnake;
