import { Helmet } from "react-helmet";
import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";

// Images
import whopper1 from "img/hognoses/20221110-DSC_7317.jpg";
import whopper3 from "img/hognoses/20210828-DSC_3004.jpg";
import whopper5 from "img/hognoses/20210828-DSC_3053.jpg";
import whopper7 from "img/hognoses/20221103-DSC_7313.jpg";
import whopper9 from "img/hognoses/20230125-DSC_7663.jpg";
import whopper10 from "img/hognoses/DSC_9158.jpg";

const albumData = [
  { img: whopper10, caption: "2020, Whopper" },
  { img: whopper5, caption: "2021, Whopper" },
  { img: whopper3, caption: "2021, Whopper" },
  { img: whopper7, caption: "2022, Whopper" },
  { img: whopper9, caption: "2023, Whopper" },
];

const animalGridData = [
  {
    id: "whopper",
    name: "Whopper",
    gender: "male",
    birthyear: "2020",
    img: whopper1,
    traits: "Albino",
  },
];

const PlainsHognoseSnake = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Plains Hognose Snakes</title>
        <meta
          name="description"
          content="See the Plains Hognose Snakes (Heterodon nasicus)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/plains-hognose-snake" />
      </Helmet>
      <HeaderText title="Plains Hognose Snakes" subtitle="Heterodon nasicus" />
      <AnimalGrid data={animalGridData} />
      <BodyText>
        I've always been interested in Hognose snakes but it took my son to
        convince me that we needed to get one. The upturned snout that they have
        is ideal for burrowing in dirt and ever since we began owning one I've
        seen it get lots of use. There are several inches of substrate in this
        one's cage which is a mix of orchid bark, coco fiber, and coco chip. Our
        Hognose spends most of his time down in the tunnels that he has dug and
        maybe 2 or 3 times a week he'll surface to see if one of us food-monkeys
        is feeding him that day. I have plenty of pictures of him with dirt on
        top of his head when he surfaces for a visit. Total derp.
      </BodyText>
      <ImageGrid imageData={albumData} maxWidth="900" />
    </ContentBox>
  );
};

export default PlainsHognoseSnake;
