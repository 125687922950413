import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import carbonara1 from "img/centralians/18-BR-HST2-F4 (sm).jpg";
import carbonara2 from "img/centralians/20210910-DSC_3561.jpg";
import carbonara4 from "img/centralians/20200812-DSC_8485.jpg";
import carbonara6 from "img/centralians/20201114-DSC_9402.jpg";
import carbonara7 from "img/centralians/20220416-DSC_6072.jpg";
import carbonara8 from "img/centralians/20220522-DSC_6380.jpg";
import carbonara9 from "img/centralians/20230311-DSC_8022.jpg";
import carbonara10 from "img/centralians/20230311-DSC_8012.jpg";
import carbonara11 from "img/centralians/20230714-DSC_1641.jpg";
import carbonara12 from "img/centralians/20230811-DSC_2194.jpg";
import carbonara13 from "img/centralians/20230811-DSC_2196.jpg";
import carbonara14 from "img/centralians/20231110-DSC_5126.jpg";

const imageData = [
  { img: carbonara1, caption: "Carbonara in 2019, pic from breeder" },
  { img: carbonara4, caption: "2020, Carbonara" },
  { img: carbonara2, caption: "2021, Carbonara" },
  { img: carbonara7, caption: "2022, Carbonara" },
  { img: carbonara8, caption: "2022, Carbonara" },
  { img: carbonara9, caption: "2023, Carbonara" },
  { img: carbonara10, caption: "2023, Carbonara" },
  { img: carbonara11, caption: "2023, Carbonara" },
  { img: carbonara12, caption: "2023, Carbonara" },
  { img: carbonara13, caption: "2023, Carbonara" },
  { img: carbonara14, caption: "2023, Carbonara" },
];

const Carbonara = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Carbonara</title>
        <meta
          name="description"
          content="See the Centralian Pythons (Morelia bredli)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/carbonara" />
      </Helmet>
      <HeaderText title="Carbonara" subtitle="Centralian Python" />

      <AnimalCard
        name="Carbonara"
        gender="female"
        birthyear="2018"
        imageName={carbonara6}
        traits="Hypomelanistic"
        acqYear="2020"
        acqNotes="Nick Mutton, Inland Reptiles"
        desc=""
      />

      <BodyText></BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Carbonara;
