import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import femRcPastelImg from "img/boaconstrictors/20221024-DSC_7206.jpg";
import vpiFemale1 from "img/boaconstrictors/20230204-DSC_7679.jpg";

const imageData = [
  { img: vpiFemale1, caption: "RC Pastel female in 2023" },
];

const RCPastelFemale = () => {
  return (
    <ContentBox>
      
      <HeaderText 
        title="" 
        subtitle="Boa Constrictor" 
      />

      <AnimalCard
        name=""
        gender="female"
        birthyear="2022"
        imageName={femRcPastelImg}
        traits="RC Pastel, VPI T+ Albino"
        acqYear="2022"
        acqNotes="Richard Ceniceros Reptiles"
        desc=""
      />

      <BodyText></BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    
    </ContentBox>
  );
};

export default RCPastelFemale;
