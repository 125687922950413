import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import femaleBrbImg from "img/brbs/20230124-DSC_7644.jpg";
import iris1 from "img/brbs/20230602-DSC_0923.jpg";
import iris2 from "img/brbs/20230814-DSC_2227.jpg";
import iris3 from "img/brbs/20230814-DSC_2225.jpg";

const imageData = [
  { img: iris1, caption: "2023, Iris" },
  { img: iris2, caption: "2023, Iris" },
  { img: iris3, caption: "2023, Iris" },
];

const Iris = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Iris</title>
        <meta
          name="description"
          content="See the Brazilian Rainbow Boas (Epicrates cenchria)
            that are being kept here and learn more about their captive husbandry."
        />
        <link
          rel="canonical"
          href="https://www.gcreptiles.com/iris"
        />
      </Helmet>
      <HeaderText title="Iris" subtitle="Brazilian Rainbow Boa" />

      <AnimalCard
        name="Iris"
        gender="female"
        birthyear="2022"
        imageName={femaleBrbImg}
        traits=""
        acqYear="2023"
        acqNotes="Rainbows-R-Us, Pomona Super Show, Jan 2023"
        desc=""
      />

      <BodyText></BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Iris;
