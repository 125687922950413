import { Helmet } from "react-helmet";
import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";

// Pirate Hans
import hans3 from "img/jungles/MaleJungle.jpg";
import hans5 from "img/jungles/20210121-DSC_9565.jpg";
import hans8 from "img/jungles/20210825-DSC_2980.jpg";

// Jinx
import jinx3 from "img/jungles/DSC_4806.jpg";
import jinx4 from "img/jungles/DSC_4823.jpg";
import jinx6 from "img/jungles/StripedFemaleJungle.jpg";
import jinx7 from "img/jungles/StripedFemaleJungle2.jpg";

// Tarzan
import tarzan1 from "img/jungles/2021-09-11 14.13.20.jpg";
import tarzan2 from "img/jungles/20210926-DSC_4288.jpg";
import tarzan8 from "img/jungles/20221024-DSC_7218.jpg";
import Image from "components/image";

const albumData = [
  { img: jinx7, caption: "1998, Jinx, perching for food" },
  { img: jinx3, caption: "2017, Jinx" },
  { img: hans3, caption: "about 2000, Pirate Hans" },
  { img: hans8, caption: "2021, Pirate Hans" },
  { img: tarzan1, caption: "2021, Tarzan" },
  { img: tarzan8, caption: "2022, Tarzan" },
];

const animalGridData = [
  {
    id: "jinx",
    name: "Jinx",
    gender: "female",
    birthyear: "1997",
    img: jinx4,
    traits: "",
  },
  {
    id: "piratehans",
    name: "Pirate Hans",
    gender: "male",
    birthyear: "",
    img: hans5,
    traits: "",
  },
  {
    id: "tarzan",
    name: "Tarzan",
    gender: "male",
    birthyear: "2015",
    img: tarzan2,
    traits: "",
  },
];

const JungleCarpetPython = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Jungle Carpet Pythons</title>
        <meta
          name="description"
          content="See the Jungle Carpet Pythons (Morelia spilota cheynei)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/jungle-carpet-python" />
      </Helmet>
      <HeaderText
        title="Jungle Carpet Pythons"
        subtitle="Morelia spilota cheynei"
      />
      <AnimalGrid data={animalGridData} />
      <BodyText>
        I probably learned about Jungle Carpet pythons from reading Reptiles
        magazine back in the mid 1990s, and I'm sure it was from an ad in the
        back of one of those that I found the email address for Dave and Tracy
        Barker of VPI. I exchanged a couple of emails with Tracy and she sent me
        this picture below and that animal became my first carpet python
        purchase in 1998.
      </BodyText>
      <Image src={jinx6} caption="1997, Jinx, pic from breeder" />
      <BodyText>
        Jinx has a stripe running the length of her body and the Barkers had
        tried proving that it was a reproducible trait but after a number of
        breedings they weren't able to get a fix on the genetics involved so
        they stopped the project.
      </BodyText>
      <BodyText>
        A year after getting Jinx a friend of mine sold me Pirate Hans, who was
        already an adult at that time. He also sold me a jungle carpet that we
        named Jungle Boogie who passed away in the 2010s. Finally in 2021 I
        found Tarzan on a local online classified ad.
      </BodyText>
      <BodyText>
        I feel like I need a few more jungles including one or more zebra
        jungles. So we'll see if I'm done bringing these in.
      </BodyText>
      <ImageGrid imageData={albumData} maxWidth="900" />
    </ContentBox>
  );
};

export default JungleCarpetPython;
