import { Helmet } from "react-helmet";
import Anchor from "components/anchor";
import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";

import ute1 from "img/coastals/2017-12-28 13.35.59.jpg";
import ute2 from "img/coastals/2019-07-07 11.21.53.jpg";
import ute5 from "img/coastals/2022-07-02 11.44.45.jpg";
import ute6 from "img/coastals/2022-07-02 11.44.52.jpg";
import ute7 from "img/coastals/20210202-DSC_9655.jpg";
import ute8 from "img/coastals/20210427-DSC_9976.jpg";
import ute11 from "img/coastals/20210720-DSC_2115.jpg";
import ute15 from "img/coastals/20221008-DSC_7114.jpg";
import ute16 from "img/coastals/DSC_5984-1.jpg";

const albumData = [
  { img: ute1, caption: "2017 Ute" },
  { img: ute16, caption: "2018 Ute" },
  { img: ute2, caption: "2019, we've never gotten any attitude from her" },
  { img: ute8, caption: "2021" },
  { img: ute11, caption: "2021" },
  { img: ute5, caption: "Jul 2022" },
  { img: ute6, caption: "Jul 2022" },
  { img: ute15, caption: "Oct 2022" },
];

const animalGridData = [
  {
    id: "ute",
    name: "Ute",
    gender: "female",
    birthyear: "2016",
    img: ute7,
    traits: "Jaguar",
  },
];

const CoastalCarpetPython = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Coastal Carpet Pythons</title>
        <meta
          name="description"
          content="See the Coastal Carpet Pythons (Morelia spilota mcdowelli)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/coastal-carpet-python" />
      </Helmet>
      <HeaderText
        title="Coastal Carpet Pythons"
        subtitle="Morelia spilota mcdowelli"
      />
      <AnimalGrid data={animalGridData} />
      <BodyText>
        Coastal Carpet pythons can be beautiful in their own right but I went
        for one with the Jaguar trait because the look they have is next-level.
        Obviously I have to address the "wobble" concerns by stating that my Jag
        Coastal will roam around her cage without any signs of wobbling unless
        she starts poking her head upward and noses around near the ceiling.
        Then she's bound to get her head upside-down and wobble as she gets
        herself righted.
      </BodyText>
      <BodyText>
        But I work from home in the same room where she is and her cage is very
        visible from my desk and I rarely see her have problems with her
        balance. Plus she's easy to handle and stunningly beautiful so I don't
        have any regrets about having her. In fact, I even plan to breed her
        some years because other people also want beautiful Jaguar coastals. I
        am assuming though that if I ever produce one with a severe equilibrium
        problem I will cull the snake.
      </BodyText>
      <BodyText>
        Interestingly{" "}
        <Anchor href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0262788">
          {" "}
          this paper{" "}
        </Anchor>
        was published in 2022 where the authors examined ball pythons of the
        Spider morph and determined that the equilibrium problems were caused by
        differences in the inner ear structure of the affected snakes. It is
        widely assumed that Coastal Carpet pythons with the Jaguar trait suffer
        because of similar conditions.
      </BodyText>
      <BodyText>
        I'm not sure if I'll ever get more Coastals in the future simply because
        of space concerns. I'm not a big fan of striped snakes for some reason
        so the Tiger gene isn't appealing to me, but I would be interested in
        the standard, banded look that many Coastals have. We'll see.
      </BodyText>
      <ImageGrid imageData={albumData} maxWidth="900" />
    </ContentBox>
  );
};

export default CoastalCarpetPython;
