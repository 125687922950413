import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
// import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
// import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// import Image from "components/image";
import { Link } from "react-router-dom";

// import gcrIcon from "img/site/logos/GC Reptiles-logos_transparent_no_text.svg";
// import gcrIconNoText from "img/site/logos/GC Reptiles-no_text.png";

const pages = [ "collection", "available", "contact"]; // , "facility", "videos"];
// const settings = ["profile", "account", "dashboard", "logout"];

export default function MainAppBar() {
  const [anchorHamburgerMenu, setAnchorHamburgerMenu] = React.useState(null);
  // const [anchorUserMenu, setAnchorUserMenu] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorHamburgerMenu(event.currentTarget);
  };
  const handleCloseNavMenu = (event) => {
    console.log("Closing hamburger menu with value:", event.target.innerText);
    setAnchorHamburgerMenu(null);
  };
  // const handleOpenUserMenu = (event) => {
  //   setAnchorUserMenu(event.currentTarget);
  // };
  // const handleCloseUserMenu = (event) => {
  //   console.log("Closing user menu with value:", event.target.innerText);
  //   setAnchorUserMenu(null);
  // };

  function capitalize(word) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }

  return (
    <AppBar position="sticky" color="primary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          
          {/* <Image src={gcrIcon} width="100" /> */}

          {/* LOGO WHEN WIDTH IS WIDE */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            GCReptiles
          </Typography>

          {/* HAMBURGER MENU ON LEFT WHEN WIDTH IS NARROW */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorHamburgerMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorHamburgerMenu)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                    <Link style={{ textDecoration: "none", color: "inherit" }} to={`/${page}`}>
                      {capitalize(page)}
                    </Link>
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* LOGO WHEN BAR IS NARROW */}
          
          {/* TODO - the icons on mui are terrible, make your own
            <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            GC Reptiles
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to={`/${page}`}
                >
                  {capitalize(page)}
                </Link>
              </Button>
            ))}
          </Box>

          {/* USER AVATAR BUTTON ON RIGHT SIDE */}
          {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="My Name Is" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorUserMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorUserMenu)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">
                    {capitalize(setting)}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
