import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";

import chili1 from "img/ebts/20210427-DSC_9952.jpg";
import chili4 from "img/ebts/2021-03-10 10.08.01.jpg";
import chili5 from "img/ebts/20210211-DSC_9667.jpg";
import chili6 from "img/ebts/2021-03-10 10.07.46.jpg";
import chili7 from "img/ebts/20210510-DSC_0055.jpg";
import chili8 from "img/ebts/20210427-DSC_9964-Edit.jpg";
import chili9 from "img/ebts/20230311-DSC_7958.jpg";
import chili10 from "img/ebts/20230610-DSC_1254.jpg";
import chili11 from "img/ebts/20230610-DSC_1259.jpg";

const imageData = [
  { img: chili4, caption: "2021, Chili" },
  { img: chili6, caption: "2021, Chili at the vet due to prolapse" },
  { img: chili5, caption: "2021, Chili putting his back into it with Egypt" },
  { img: chili7, caption: "2021, Chili" },
  { img: chili8, caption: "2021, Chili, bath time" },
  { img: chili9, caption: "2023, Chili, lunch" },
  { img: chili10, caption: "2023, Chili" },
  { img: chili11, caption: "2023, Chili" },
];

const Chili = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Chili</title>
        <meta
          name="description"
          content="See the Eastern Box Turtles (Terrapene carolina carolina)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/chili" />
      </Helmet>
      <HeaderText title="Chili" subtitle="Eastern Box Turtle" />

      <AnimalCard
        name="Chili"
        gender="male"
        birthyear=""
        imageName={chili1}
        traits=""
        acqYear="2018"
        acqNotes="Joey Mugleston, Great Basin Serpentarium"
        desc=""
      />

      <BodyText></BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Chili;
