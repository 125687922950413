import { Helmet } from "react-helmet";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import Image from "components/image";

import morphmarketlogo from "img/available/morphmarketlogo.png";

const storeUrl = "https://www.morphmarket.com/stores/gregcooper669/#store-listings";

const Available = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Available Reptiles</title>
        <meta
          name="description"
          content="Looking to buy? These are the reptiles currently for sale."
        />
      </Helmet>
      <Image src={morphmarketlogo} clickable="false" />
      <BodyText>
        To see the animals that I have for sale, go to my store on{" "}
        <a href={storeUrl} alt="MorphMarket">MorphMarket</a>.
      </BodyText>
    </ContentBox>
  );
};

export default Available;
