import React from "react";
import { hydrate, render } from "react-dom";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import "./App.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StyledEngineProvider } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Main Menu Items
import Home from "pages/home/home";
import Available from "pages/available/available";
import Collection from "pages/collection/collection-grid";
import Contact from "pages/contact/contact";
import Facility from "pages/facility/facility";

// Species in Collection
import AzMtnKingsnake from "pages/collection/arizona-mountain-kingsnakes";
import BallPython from "pages/collection/ball-pythons";
import BlackPinesnake from "pages/collection/black-pinesnakes";
import BoaConstrictor from "pages/collection/boa-constrictors";
import BrazilianRainbowBoa from "pages/collection/brazilian-rainbow-boas";
import CentralianPython from "pages/collection/centralian-pythons";
import ChineseKingRatsnake from "pages/collection/chinese-king-ratsnakes";
import CoastalCarpetPython from "pages/collection/coastal-carpet-python";
import DiamondPython from "pages/collection/diamond-python";
import DumerilsBoa from "pages/collection/dumerils-boa";
import EasternKingsnake from "pages/collection/eastern-kingsnake";
import GrayBandedKingsnake from "pages/collection/gray-banded-kingsnake";
import GreatBasinGopherSnake from "pages/collection/great-basin-gopher-snake";
import HonduranMilkSnake from "pages/collection/honduran-milksnake";
import JungleCarpetPython from "pages/collection/jungle-carpet-python";
import PapuanCarpetPython from "pages/collection/papuan-carpet-python";
import PlainsHognoseSnake from "pages/collection/plains-hognose-snake";
import LeopardGecko from "pages/collection/leopard-gecko";
import EasternBoxTurtle from "pages/collection/eastern-box-turtle";

// Individual Animals
import Alfredo from "pages/animal/alfredo";
import Alien from "pages/animal/alien";
import Asmodeus from "pages/animal/asmodeus";
import Baconator from "pages/animal/baconator";
import Bear from "pages/animal/bear";
import Blister from "pages/animal/blister";
import Blaze from "pages/animal/blaze";
import Blizzard from "pages/animal/blizzard";
import Carbonara from "pages/animal/carbonara";
import Cheesecake from "pages/animal/cheesecake";
import Chili from "pages/animal/chili";
import Coconut from "pages/animal/coconut";
import Cruelty from "pages/animal/cruelty";
import Crush from "pages/animal/crush";
import Cuddles from "pages/animal/cuddles";
import DrPepper from "pages/animal/drpepper";
import Egypt from "pages/animal/egypt";
import Firefly from "pages/animal/firefly";
import Grit from "pages/animal/grit";
import Iris from "pages/animal/iris";
import Jinx from "pages/animal/jinx";
import Lol from "pages/animal/lol";
import Malice from "pages/animal/malice";
import MelloYello from "pages/animal/mello";
import Moose from "pages/animal/moose";
import Mystique from "pages/animal/mystique";
import Nightshade from "pages/animal/nightshade";
import PirateHans from "pages/animal/piratehans";
import RCPastelFemale from "pages/animal/rcpastelfemale";
import RCPastelMale from "pages/animal/rcpastelmale";
import Saturcka from "pages/animal/saturcka";
import Shelly from "pages/animal/shelly";
import SixteenLasers from "pages/animal/16lasers";
import Snuggles from "pages/animal/snuggles";
import Suncka from "pages/animal/suncka";
import Sunset from "pages/animal/sunset";
import Tarzan from "pages/animal/tarzan";
import Ute from "pages/animal/ute";
import Whopper from "pages/animal/whopper";
import Wiggles from "pages/animal/wiggles";
import Xavier from "pages/animal/xavier";

function Error() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Typography variant="h4">Well that didn't work.</Typography>
    </Box>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<Error />}>
      <Route index="true" element={<Home />} />
      
      {/* Main Menu Items */}
      <Route path="available" element={<Available />} />
      <Route path="contact" element={<Contact />} />
      <Route path="facility" element={<Facility />} />
      <Route path="collection" element={<Collection />} />

      {/* Species in Collection */}
      <Route path="arizona-mountain-kingsnakes" element={<AzMtnKingsnake />} />
      <Route path="ball-pythons" element={<BallPython />} />
      <Route path="black-pinesnakes" element={<BlackPinesnake />} />
      <Route path="boa-constrictors" element={<BoaConstrictor />} />
      <Route path="brazilian-rainbow-boas" element={<BrazilianRainbowBoa />} />
      <Route path="centralian-pythons" element={<CentralianPython />} />
      <Route path="chinese-king-ratsnakes" element={<ChineseKingRatsnake />} />
      <Route path="coastal-carpet-python" element={<CoastalCarpetPython />} />
      <Route path="diamond-python" element={<DiamondPython />} />
      <Route path="dumerils-boa" element={<DumerilsBoa />} />
      <Route path="eastern-kingsnake" element={<EasternKingsnake />} />
      <Route path="gray-banded-kingsnake" element={<GrayBandedKingsnake />} />
      <Route path="great-basin-gopher-snake" element={<GreatBasinGopherSnake />} />
      <Route path="honduran-milksnake" element={<HonduranMilkSnake />} />
      <Route path="jungle-carpet-python" element={<JungleCarpetPython />} />
      <Route path="papuan-carpet-python" element={<PapuanCarpetPython />} />
      <Route path="plains-hognose-snake" element={<PlainsHognoseSnake />} />
      <Route path="leopard-gecko" element={<LeopardGecko />} />
      <Route path="eastern-box-turtle" element={<EasternBoxTurtle />} />

      {/* Individual Animals */}
      <Route path="16lasers" element={<SixteenLasers />} />
      <Route path="alfredo" element={<Alfredo />} />
      <Route path="alien" element={<Alien />} />
      <Route path="asmodeus" element={<Asmodeus />} />
      <Route path="baconator" element={<Baconator />} />
      <Route path="bear" element={<Bear />} />   
      <Route path="blister" element={<Blister />} />   
      <Route path="blaze" element={<Blaze />} />   
      <Route path="blizzard" element={<Blizzard />} />
      <Route path="carbonara" element={<Carbonara />} />
      <Route path="cheesecake" element={<Cheesecake />} />
      <Route path="chili" element={<Chili />} />   
      <Route path="coconut" element={<Coconut />} />
      <Route path="cruelty" element={<Cruelty />} />   
      <Route path="crush" element={<Crush />} />
      <Route path="cuddles" element={<Cuddles />} />   
      <Route path="drpepper" element={<DrPepper />} />
      <Route path="egypt" element={<Egypt />} />   
      <Route path="iris" element={<Iris />} />
      <Route path="firefly" element={<Firefly />} />
      <Route path="grit" element={<Grit />} /> 
      <Route path="jinx" element={<Jinx />} />   
      <Route path="lol" element={<Lol />} />   
      <Route path="malice" element={<Malice />} />   
      <Route path="mello" element={<MelloYello />} />   
      <Route path="moose" element={<Moose />} />   
      <Route path="mystique" element={<Mystique />} />   
      <Route path="nightshade" element={<Nightshade />} />
      <Route path="piratehans" element={<PirateHans />} />   
      <Route path="rcpastelfemale" element={<RCPastelFemale />} />
      <Route path="rcpastelmale" element={<RCPastelMale />} />
      <Route path="saturcka" element={<Saturcka />} />   
      <Route path="shelly" element={<Shelly />} />   
      <Route path="snuggles" element={<Snuggles />} />   
      <Route path="suncka" element={<Suncka />} />
      <Route path="sunset" element={<Sunset />} />   
      <Route path="tarzan" element={<Tarzan />} />   
      <Route path="ute" element={<Ute />} />   
      <Route path="xavier" element={<Xavier />} />   
      <Route path="whopper" element={<Whopper />} />   
      <Route path="wiggles" element={<Wiggles />} />   

      {/* Default/Error */}
      <Route path="*" element={<Error />} />
    </Route>
  )
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <RouterProvider router={router}></RouterProvider>
      </StyledEngineProvider>
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <RouterProvider router={router}></RouterProvider>
      </StyledEngineProvider>
    </React.StrictMode>,
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
