import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import malice1 from "img/ckrs/20220604-DSC_6684.jpg";
import malice2 from "img/ckrs/20230304-DSC_7798.jpg";
import malice3 from "img/ckrs/20230308-DSC_7919.jpg";
import malice4 from "img/ckrs/20230311-DSC_7925.jpg";
import malice5 from "img/ckrs/20231027-DSC_5111.jpg";

const imageData = [
  { img: malice2, caption: "2023, Malice" },
  { img: malice3, caption: "2023, Malice" },
  { img: malice4, caption: "2023, Malice" },
  { img: malice5, caption: "2023, Malice" },
];

const Malice = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Malice</title>
        <meta
          name="description"
          content="See the Chinese King Ratsnakes (Elaphe carinata)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/malice" />
      </Helmet>
      <HeaderText title="Malice" subtitle="Chinese King Ratsnake" />

      <AnimalCard
        name="Malice"
        gender="female"
        birthyear="2020"
        imageName={malice1}
        traits=""
        acqYear="2022"
        acqNotes="Joshua Buchwald, Cold Blooded Kingdom"
        desc=""
      />

      <BodyText></BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Malice;
