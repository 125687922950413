import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import { useMediaQuery } from "@mui/material";
import { pink, blue } from "@mui/material/colors";
import { Box } from "@mui/system";

const AnimalGridCard = ({ id, name, gender, birthyear, imageName, traits }) => {
  const mediumScreenWidth = useMediaQuery("(min-width:480px");
  const wideScreenWidth = useMediaQuery("(min-width:650px)");

  const isMale = gender ? gender.toLowerCase() === "male" : null;
  const isFemale = gender ? gender.toLowerCase() === "female" : null;

  return (
    <Card
      sx={{
        width: wideScreenWidth ? 260 : mediumScreenWidth ? 175 : 147,
        height: wideScreenWidth ? 335 : mediumScreenWidth ? 230 : 210,
        margin: 1.5,
      }}
    >
      <Link to={`/${id}`} style={{ textDecoration: "none" }}>
        {/* Image */}
        <CardMedia
          component="img"
          height={wideScreenWidth ? "180" : "107"}
          image={imageName}
          alt={name}
        />

        <CardContent>
          {/* Name */}
          <Typography
            variant={wideScreenWidth ? "h4" : "h5"}
            component="div"
            color="#423B22"
          >
            {name}
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
            {/* Gender Icon */}
            {isMale && <MaleIcon sx={{ color: blue[500], paddingRight: 1}} />}
            {isFemale && <FemaleIcon sx={{ color: pink[300], paddingRight: 1 }} />}

            {/* Birth Year */}
            <Typography
              component="span"
              variant={mediumScreenWidth ? "body1" : "body2"}
              color="#846D44"
            >
              {birthyear}
            </Typography>
          </Box>

          {/* Traits */}
          <Typography
            variant={wideScreenWidth ? "h6" : "h6"}
            component="div"
            color="#423B22"
            fontWeight="light"
            fontStyle="italic"
          >
            {traits}
          </Typography>
        </CardContent>
      </Link>
    </Card>
  );
};

export default AnimalGridCard;
