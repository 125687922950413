import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";
import Video from "components/video";

import honduran2 from "img/hondurans/2015-10-27 20.42.03-2.jpg";
import honduran3 from "img/hondurans/2019-11-06 18.51.10.jpg";
import honduran5 from "img/hondurans/20221008-DSC_7178.jpg";
import honduran6 from "img/hondurans/2023-10-13 08.36.44.jpg";
import honduran7 from "img/hondurans/20220302-DSC_5658.jpg";

const imageData = [
  { img: honduran2, caption: "2015, Saturcka" },
  { img: honduran3, caption: "2019, Saturcka" },
  { img: honduran7, caption: "2022, Saturcka" },
  { img: honduran6, caption: "2023, Saturcka" },
];

const Saturcka = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Saturcka</title>
        <meta
          name="description"
          content="See the Honduran Milksnakes (Lampropeltis triangulum hondurensis)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/saturcka" />
      </Helmet>
      <HeaderText title="Saturcka" subtitle="Honduran Milksnake" />

      <AnimalCard
        name="Saturcka"
        gender="female"
        birthyear="2015"
        imageName={honduran5}
        traits="Albino, Het Hypo"
        acqYear="2015"
        acqNotes="Dan and Colette Sutherland, TSK, Wasatch Reptile Expo"
        desc=""
      />

      <BodyText>
        When I bought Saturcka from Dan Sutherland at TSK Reptile he told me
        that the snakes parents were bitey, but we've never had an issue with
        Saturcka biting any of us. She was pretty wiggly / flighty in her
        younger years but now she'll relax and let you hold her for a while.
      </BodyText>
      <Video
        src="https://www.youtube.com/embed/oQHZvyvqVm4"
        caption="Getting outside with a Honduran milksnake"
      />
      <BodyText>
        One thing that I've thought about with the Hondurans is that when they
        have deep humid substrate to burrow into they don't seem to come out to
        the surface often enough to warrant a glass-front display cage. I work
        from home and I'm in the snake room all day and I'm guessing Saturcka
        comes to the surface and shows herself maybe twice a month. They may go
        into tubs at some point.
      </BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default Saturcka;
