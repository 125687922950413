import azMtnKingsnakeSm from "img/collection/arizona-mountain-kingsnake-155w.jpg";
import azMtnKingsnakeMd from "img/collection/arizona-mountain-kingsnake-260w.jpg";
import ballPythonSm from "img/collection/ball-python-155w.jpg";
import ballPythonMd from "img/collection/ball-python-260w.jpg";
import blackPinesnakeSm from "img/collection/black-pine-snake-155w.jpg";
import blackPinesnakeMd from "img/collection/black-pine-snake-260w.jpg";
import boaConstrictorSm from "img/collection/boa-constrictor-155w.jpg";
import boaConstrictorMd from "img/collection/boa-constrictor-260w.jpg";
import brazilianRainbowBoaSm from "img/collection/brazilian-rainbow-boa-155w.jpg";
import brazilianRainbowBoaMd from "img/collection/brazilian-rainbow-boa-260w.jpg";
import centralianPythonSm from "img/collection/centralian-python-155w.jpg";
import centralianPythonMd from "img/collection/centralian-python-260w.jpg";
import chineseKingRatsnakeSm from "img/collection/chinese-king-ratsnake-155w.jpg";
import chineseKingRatsnakeMd from "img/collection/chinese-king-ratsnake-260w.jpg";
import coastalCarpetPythonSm from "img/collection/coastal-carpet-python-155w.jpg";
import coastalCarpetPythonMd from "img/collection/coastal-carpet-python-260w.jpg";
import diamondPythonSm from "img/collection/diamond-python-155w.jpg";
import diamondPythonMd from "img/collection/diamond-python-260w.jpg";
import dumerilsBoaSm from "img/collection/dumerils-boa-155w.jpg";
import dumerilsBoaMd from "img/collection/dumerils-boa-260w.jpg";
import easternKingsnakeSm from "img/collection/eastern-kingsnake-155w.jpg";
import easternKingsnakeMd from "img/collection/eastern-kingsnake-260w.jpg";
import grayBandedKingsnakeSm from "img/collection/gray-banded-kingsnake-155w.jpg";
import grayBandedKingsnakeMd from "img/collection/gray-banded-kingsnake-260w.jpg";
import greatBasinGopherSnakeSm from "img/collection/great-basin-gophersnake-155w.jpg";
import greatBasinGopherSnakeMd from "img/collection/great-basin-gophersnake-260w.jpg";
import honduranMilkSnakeSm from "img/collection/honduran-milksnake-155w.jpg";
import honduranMilkSnakeMd from "img/collection/honduran-milksnake-260w.jpg";
import jungleCarpetPythonSm from "img/collection/jungle-carpet-python-155w.jpg";
import jungleCarpetPythonMd from "img/collection/jungle-carpet-python-260w.jpg";
import leopardGeckoSm from "img/collection/leopard-gecko-155w.jpg";
import leopardGeckoMd from "img/collection/leopard-gecko-260w.jpg";
import papuanCarpetPythonSm from "img/collection/papuan-carpet-python-155w.jpg";
import papuanCarpetPythonMd from "img/collection/papuan-carpet-python-260w.jpg";
import plainsHognoseSnakeSm from "img/collection/plains-hognose-snake-155w.jpg";
import plainsHognoseSnakeMd from "img/collection/plains-hognose-snake-260w.jpg";

const collectionData = [
    {
      id: 'arizona-mountain-kingsnakes',
      smImg: azMtnKingsnakeSm,
      mdImg: azMtnKingsnakeMd,
      species: "Arizona Mountain Kingsnakes",
      sciName: "Lampropeltis pyromelana pyromelana",
    },
    {
      id: 'ball-pythons',
      smImg: ballPythonSm,
      mdImg: ballPythonMd,
      species: "Ball Pythons",
      sciName: "Python regius",
    },
    {
      id: 'black-pinesnakes',
      smImg: blackPinesnakeSm,
      mdImg: blackPinesnakeMd,
      species: "Black Pine Snakes",
      sciName: "Pituophis melanoleucus lodingi",
    },

    {
      id: 'boa-constrictors',
      smImg: boaConstrictorSm,
      mdImg: boaConstrictorMd,
      species: "Boa Constrictors",
      sciName: "Boa imperator",
    },
    {
      id: 'brazilian-rainbow-boas',
      smImg: brazilianRainbowBoaSm,
      mdImg: brazilianRainbowBoaMd,
      species: "Brazilian Rainbow Boas",
      sciName: "Epicrates cenchria",
    },
    {
      id: 'centralian-pythons',
      smImg: centralianPythonSm,
      mdImg: centralianPythonMd,
      species: "Centralian Pythons",
      sciName: "Morelia bredli",
    },
    {
      id: 'chinese-king-ratsnakes',
      smImg: chineseKingRatsnakeSm,
      mdImg: chineseKingRatsnakeMd,
      species: "Chinese King Ratsnakes",
      sciName: "Elaphe carinata",
    },
    {
      id: 'coastal-carpet-python',
      smImg: coastalCarpetPythonSm,
      mdImg: coastalCarpetPythonMd,
      species: "Coastal Carpet Pythons",
      sciName: "Morelia spilota mcdowelli",
    },
    {
      id: 'diamond-python',
      smImg: diamondPythonSm,
      mdImg: diamondPythonMd,
      species: "Diamond Pythons",
      sciName: "Morelia spilota spilota",
    },
    {
      id: 'dumerils-boa',
      smImg: dumerilsBoaSm,
      mdImg: dumerilsBoaMd,
      species: "Dumeril's Boas",
      sciName: "Acrantophis dumerili",
    },
    {
      id: 'eastern-kingsnake',
      smImg: easternKingsnakeSm,
      mdImg: easternKingsnakeMd,
      species: "Eastern Kingsnakes",
      sciName: "Lampropeltis getula",
    },
    {
      id: 'gray-banded-kingsnake',
      smImg: grayBandedKingsnakeSm,
      mdImg: grayBandedKingsnakeMd,
      species: "Gray-banded Kingsnakes",
      sciName: "Lampropeltis alterna",
    },
    {
      id: 'great-basin-gopher-snake',
      smImg: greatBasinGopherSnakeSm,
      mdImg: greatBasinGopherSnakeMd,
      species: "Great Basin Gopher Snakes",
      sciName: "Pituophis catenifer deserticola",
    },
    {
      id: 'honduran-milksnake',
      smImg: honduranMilkSnakeSm,
      mdImg: honduranMilkSnakeMd,
      species: "Honduran Milksnakes",
      sciName: "Lampropeltis triangulum hondurensis",
    },
    {
      id: 'jungle-carpet-python',
      smImg: jungleCarpetPythonSm,
      mdImg: jungleCarpetPythonMd,
      species: "Jungle Carpet Pythons",
      sciName: "Morelia spilota cheynei",
    },
    {
      id: 'papuan-carpet-python',
      smImg: papuanCarpetPythonSm,
      mdImg: papuanCarpetPythonMd,
      species: "Papuan Carpet Pythons",
      sciName: "Morelia spilota variegata",
    },
    {
      id: 'plains-hognose-snake',
      smImg: plainsHognoseSnakeSm,
      mdImg: plainsHognoseSnakeMd,
      species: "Plains Hognose Snakes",
      sciName: "Heterodon nasicus",
    },
    {
      id: 'leopard-gecko',
      smImg: leopardGeckoSm,
      mdImg: leopardGeckoMd,
      species: "Leopard Geckos",
      sciName: "Eublepharis macularius",
    },
  ];

  export default collectionData;
  