import { Helmet } from "react-helmet";
import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import Image from "components/image";
import ImageGrid from "components/imagegrid";

import femPine1 from "img/blackpines/20220409-DSC_6031.jpg";
import femPine2 from "img/blackpines/20220409-DSC_6036.jpg";
import femPine3 from "img/blackpines/20220409-DSC_6038.jpg";
import femPine4 from "img/blackpines/20220604-DSC_6719.jpg";
import femPine5 from "img/blackpines/20220723-DSC_6815.jpg";

import asmodeus1 from "img/blackpines/20221008-DSC_7168.jpg";
import nightshade1 from "img/blackpines/20220723-DSC_6812.jpg";

const animalGridData = [
  {
    id: "asmodeus",
    name: "Asmodeus",
    gender: "male",
    birthyear: "2021",
    img: asmodeus1,
    traits: "",
  },
  {
    id: "nightshade",
    name: "Nightshade",
    gender: "female",
    birthyear: "2021",
    img: nightshade1,
    traits: "",
  },
];

const earlyBPData = [
  { img: femPine1, caption: "Apr 2022, female black pine snake" },
  { img: femPine2, caption: "Apr 2022, female black pine snake" },
  { img: femPine3, caption: "Apr 2022, female black pine snake" },
  {
    img: femPine4,
    caption: "Jun 2022, trying to capture some sunlight on the female",
  },
];

const BlackPinesnake = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Black Pine Snakes</title>
        <meta
          name="description"
          content="See the Black Pine Snakes (Pituophis melanoleucus lodingi)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/black-pinesnakes" />
      </Helmet>

      <HeaderText
        title="Black Pine Snakes"
        subtitle="Pituophis melanoleucus lodingi"
      />

      <AnimalGrid data={animalGridData} />

      <BodyText>
        Black pine snakes are a very recent addition for me. So recent that I
        don't have very many decent pictures of them.
      </BodyText>

      <BodyText>
        I bought mine as yearlings in 2022 from Summer Sutherland of "The Snake
        Keeper" fame. TSK acquired theirs from Envy Reptiles in Springville,
        Utah. Black pine snakes are listed as Threatened by the USFWS and an
        interstate commerce permit is required to sell or transport them across
        state lines.
      </BodyText>

      <BodyText>
        The best part about showing pictures of them is that I could show you
        any black pine snake and it wouldn't really matter. They all look the
        same. Okay maybe that isn't entirely true. In the case of the pair that
        I have, the female has a small white patch on her chin and so that is
        how I tell them apart. Overall though mine have only a faint trace of
        any pattern.
      </BodyText>

      <Image
        src={femPine5}
        caption="July 2022, female black pine snake"
      />

      <BodyText>
        Mine can be a little bit hissy, especially the female, but we get them
        out frequently to try and get them accustomed to being handled. Once
        they stop hissing they have actually had moments where they will sit
        still on your lap for a long time and be calm.
      </BodyText>

      <ImageGrid imageData={earlyBPData} maxWidth="900" />
    </ContentBox>
  );
};

export default BlackPinesnake;
