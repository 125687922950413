import { Helmet } from "react-helmet";
import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import Image from "components/image";
import ImageGrid from "components/imagegrid";

// Images
import dum2 from "img/dumerils/2019-12-28 17.14.41.jpg";
import dum3 from "img/dumerils/2020-07-28 11.41.13.jpg";
import dum5 from "img/dumerils/2021-05-15 18.35.28.jpg";
import dum8 from "img/dumerils/20200811-DSC_8467.jpg";
import dum9 from "img/dumerils/D1.jpg";
import dum10 from "img/dumerils/DSC_9691(2).jpg";
import dum11 from "img/dumerils/Img_2015-02-02_03231 (2).jpg";

// Bear
import bear3 from "img/dumerils/20210828-DSC_3162.jpg";

// Cuddles
import cuddles5 from "img/dumerils/Female 2015-07-05-2.jpg";
import cuddles7 from "img/dumerils/20210911-DSC_3571.jpg";
import cuddles10 from "img/dumerils/20211001-DSC_4374.jpg";

// Moose
import moose3 from "img/dumerils/20200912-DSC_8729.jpg";
import moose7 from "img/dumerils/20210202-DSC_9636.jpg";
import moose9 from "img/dumerils/Male 2015-07-05.jpg";
import moose10 from "img/dumerils/2019-05-18 15.16.34.jpg";
import moose11 from "img/dumerils/2019-06-09 22.08.57.jpg";

// Snuggles
import snuggles2 from "img/dumerils/F1_20200912-DSC_8612.jpg";
import snuggles4 from "img/dumerils/20210202-DSC_9626.jpg";

// Wiggles
import wiggles1 from "img/dumerils/F9_DSC_8551.jpg";
import wiggles3 from "img/dumerils/F9_20200912-DSC_8635.jpg";

const albumData = [
  { img: bear3, caption: "Bear" },
  { img: cuddles7, caption: "Cuddles" },
  { img: cuddles10, caption: "Cuddles" },
  { img: moose3, caption: "Moose" },
  { img: moose7, caption: "Moose" },
  { img: moose10, caption: "2019, good times with Moose, so much drama that one" },
  { img: moose11, caption: "2019, Getting a squeeze from Moose" },
  { img: snuggles2, caption: "Snuggles" },
  { img: wiggles3, caption: "Wiggles" },
  { img: dum11, caption: "2015, Fistful of babies" },
  { img: dum9, caption: "2018, baby" },
  { img: dum2, caption: "Dec 2019, Cuddles and Moose lock-up" },
  {
    img: dum5,
    caption:
      "May 2021, trying to eat himself, snakes are not the brightest animals",
  },
  { img: dum10, caption: "2021, Cuddles in top cage, Moose in bottom" },
];

const animalGridData = [
  {
    id: "moose",
    name: "Moose",
    gender: "male",
    birthyear: "1999",
    img: moose9,
    traits: "",
  },
  {
    id: "cuddles",
    name: "Cuddles",
    gender: "female",
    birthyear: "1999",
    img: cuddles5,
    traits: "",
  },
  {
    id: "snuggles",
    name: "Snuggles",
    gender: "female",
    birthyear: "2020",
    img: snuggles4,
    traits: "",
  },
  {
    id: "wiggles",
    name: "Wiggles",
    gender: "female",
    birthyear: "2020",
    img: wiggles1,
    traits: "",
  },
];

const DumerilsBoa = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Dumeril's Boas</title>
        <meta
          name="description"
          content="See the Dumeril's Boas (Acrantophis dumerili)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/dumerils-boa" />
      </Helmet>
      <HeaderText title="Dumeril's Boas" subtitle="Acrantophis dumerili" />
      <AnimalGrid data={animalGridData} />
      <BodyText>
        In the late 1990s when I was getting into the adult phase of my reptile
        keeping I met friends nearby who shared my reptile keeping passion, and
        after a couple years of talking about it we went to our first big
        reptile show in San Diego California in the summer of 2000. It was quite
        the road trip. Obviously we talked reptiles for the entire 12 hour drive
        down and back, and while we were there we saw animals I had never seen
        before in real life. Of course, I couldn't make such a long trip and
        come home empty-handed so I picked up a pair of blood pythons and a pair
        of Dumerils boas.
      </BodyText>
      <BodyText>
        The Dumerils impressed me because they had an intricate, high-contrast
        pattern that looked like nothing I had ever seen.
      </BodyText>
      <Image src={dum8} caption="2020 litter of Dumeril's boas" />
      <BodyText>
        It's funny that by the mid 2000's I had lost track of which Dumeril's
        was the male and which was the female. I paired the two together for my
        first attempt at breeding them in 2007 and when the babies were born,
        both parents were still in the cage. So I still didn't know which was
        which. Finally, they bred again in 2010 and the babies were born when
        the parents were separated. I made sure to do a better job keeping track
        after that.
      </BodyText>
      <Image
        src={dum3}
        caption="2020, escapee baby that I found in a notebook on my shelf"
      />
      <BodyText>
        Another funny thing with my Dumeril's is that when I kept them in cages
        with sliding glass doors the doors weren't always closed tightly the
        night when babies were being born, so sometimes I'd come in and there
        would be a few babies in the cage with mom, and then signs on the floor
        that there were more babies roaming around. We've had to do full
        searches of our basement trying to see what we can find. One time I
        found a baby when I pulled a notebook down from a shelf and opened it
        up and there was a baby in between the rings.
      </BodyText>

      <ImageGrid imageData={albumData} maxWidth="900" />

    </ContentBox>
  );
};

export default DumerilsBoa;
