import { Helmet } from "react-helmet";
import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";
import Image from "components/image";
import Video from "components/video";

import ebt1 from "img/ebts/2018-06-15 16.10.34-1.jpg";
import ebt2 from "img/ebts/20210427-DSC_9963.jpg";
import ebt3 from "img/ebts/20210427-DSC_9960.jpg";

import ebt4 from "img/ebts/scan0027.jpg";
import ebt5 from "img/ebts/2020-08-12 15.59.53.jpg";
import ebt6 from "img/ebts/20210428-DSC_9984.jpg";
import ebt8 from "img/ebts/DSC_5999-2.jpg";
import ebt9 from "img/ebts/DSC_6007-7.jpg";

// Blaze
import blaze1 from "img/ebts/2019-03-04 10.28.35.jpg";
import blaze3 from "img/ebts/2019-05-28 19.04.48.jpg";
import blaze6 from "img/ebts/20210427-DSC_9935.jpg";

// Chili
import chili1 from "img/ebts/20210427-DSC_9952.jpg";
import chili2 from "img/ebts/20210510-DSC_0058.jpg";
import chili3 from "img/ebts/20210427-DSC_9964.jpg";

// Egypt
import egypt1 from "img/ebts/20210427-DSC_9953.jpg";
import egypt5 from "img/ebts/20210429-DSC_9992.jpg";

// Shelly
import shelly1 from "img/ebts/20210427-DSC_9944.jpg";
import shelly3 from "img/ebts/2018-11-30 13.24.10.jpg";
import shelly6 from "img/ebts/20210429-DSC_9988.jpg";
import shelly8 from "img/ebts/20210429-DSC_9995.jpg";

// Turbo
import turbo2 from "img/ebts/2020-10-12 20.20.58.jpg";

// Yertle
import yertle1 from "img/ebts/20200924-DSC_8911.jpg";

const albumData = [
  { img: shelly3, caption: "2018, Shelly, having a vet appt for her leg" },
  {
    img: ebt8,
    caption:
      "2018, indoor habitat v1, the black containers were too small for adult box turtles",
  },
  { img: ebt9, caption: "2018, indoor habitat v1" },
  { img: blaze1, caption: "2019, Blaze" },
  { img: blaze3, caption: "2019, Blaze" },
  { img: yertle1, caption: "2020, Yertle" },
  { img: turbo2, caption: "2020, Turbo" },
  { img: egypt5, caption: "2021, Egypt" },
  { img: chili2, caption: "2021, Chili, dirtying the fresh water" },
  { img: chili3, caption: "2021, Chili, smoldering for the ladies" },
  { img: shelly6, caption: "2021, Shelly" },
  { img: shelly8, caption: "2021, Shelly, pretending to be a submarine" },
  { img: ebt2, caption: "2021, Blaze and Egypt" },
  { img: ebt3, caption: "2021, Egypt giving Blaze a piggy-back ride" },
  { img: ebt6, caption: "2021, indoor habitat v2" },
  { img: ebt5, caption: "2021, indoor habitat v2" },
];

const animalGridData = [
  {
    id: "blaze",
    name: "Blaze",
    gender: "male",
    birthyear: "",
    img: blaze6,
    traits: "",
  },
  {
    id: "egypt",
    name: "Egypt",
    gender: "female",
    birthyear: "",
    img: egypt1,
    traits: "",
  },
  {
    id: "chili",
    name: "Chili",
    gender: "male",
    birthyear: "",
    img: chili1,
    traits: "",
  },
  {
    id: "shelly",
    name: "Shelly",
    gender: "female",
    birthyear: "",
    img: shelly1,
    traits: "",
  },
];

const EasternBoxTurtle = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Eastern Box Turtles</title>
        <meta
          name="description"
          content="See the Eastern Box Turtles (Terrapene carolina carolina)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/eastern-box-turtle" />
      </Helmet>
      <HeaderText
        title="Eastern Box Turtles"
        subtitle="Terrapene carolina carolina"
      />
      <AnimalGrid data={animalGridData} />
      <BodyText>
        I was raised in Northern Virginia and was outdoors a lot as a kid so
        naturally I came in contact with Eastern Box turtles. I don't know if
        they are as prevalent in Fairfax County now as they were back in the 70s
        and 80s but we'd find several every year. One of our secret spots was
        actually a busy road that ran through a wooded area on the way to the
        big shopping mall in town. We called it "turtle road" because we saw
        many turtles trying to cross it. Sometimes we'd get out and catch one if
        Mom was in a good mood.
      </BodyText>
      <BodyText>
        I made a pen in the backyard of our house with chicken wire across the
        top and built a small pond and put a trio of box turtles inside. I had
        two males and a female and I would bring them inside and brumate them
        during the winter, although looking back on it I don't know why I did
        that. They probably would have done better outside. One summer I found a
        baby turtle in the pen which was my first time - if I can take any
        credit for it at all - breeding reptiles in captivity.
      </BodyText>
      <Image
        src={ebt4}
        caption="1979, family photo with an Eastern Box turtle keeping everyone happy"
      />
      <BodyText>
        There was a classic family moment once when we were taking our first and
        only professional family portrait in the backyard. The photographer got
        setup with his camera on a tripod and everyone got in position except
        for my younger brother Mark. Mark was crying and wanted nothing to do
        with being in the picture. Finally, to get him to submit, my parents
        allowed him to be in the photo holding one of the turtles we kept in the
        outdoor pen behind us. That turned into one of the most iconic family
        photos of my childhood.
      </BodyText>
      <Video
        src="https://www.youtube.com/embed/GHCYqOYAtqI"
        caption="How to Build an Indoor Turtle Habitat"
      />
      <BodyText>
        In my adult years I've looked for captive bred Eastern Box turtles and
        was surprised when someone local to me announced that he had a group he
        wanted to sell, although these are definitely not captive bred animals.
        I hurried and bought all of them. The group was male heavy so I replaced
        two of the males with a female that I found online so now I have 2.2.
      </BodyText>
      <Image
        src={ebt1}
        caption="2018, finishing off the extras from my son's wedding reception"
      />
      <BodyText>
        I keep them inside year-round but I need to find a way to move them
        outside for 8 or 9 months of the year. I built an outdoor enclosure when
        I first got them but after using it for a few months I found that it was
        just too hot for them no matter what I did to try and shade them from
        the harsh Utah summertime heat. Hopefully I can find another location
        outdoors where they can thrive.
      </BodyText>
      <BodyText>
        Like everything else I keep, I was hoping that I'd be successful
        breeding them. The older female has produced eggs several times but
        either I can't incubate them correctly or they just weren't fertile. I
        think at this point I won't try and incubate them artificially any more.
      </BodyText>
      <ImageGrid imageData={albumData} maxWidth="900" />
    </ContentBox>
  );
};

export default EasternBoxTurtle;
