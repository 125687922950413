import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import Image from "components/image";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";

import BackgroundImage from "img/site/Malice-1.jpg";
import EKing1 from "img/facility/larry_bev255.jpg";
import EKing2 from "img/facility/20230321-DSC_8113.jpg";
import YouTube from "img/site/yt_logo_rgb_dark.png";

const youTubeUrl = "https://www.youtube.com/@gregcooperreptiles";

export default function Home() {
  const wideScreenWidth = useMediaQuery("(min-width:650px)");

  return (
    <Paper
      sx={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundColor: "primary.dark",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        color: "white",
        padding: 3,
      }}
    >
      <Helmet>
        <title>Greg Cooper Reptiles</title>
        <meta
          name="description"
          content="This is the home for Greg Cooper's reptile collection, home for many species of pythons, boas, and colubrids"
        />
        <link rel="canonical" href="https://www.gcreptiles.com/" />
      </Helmet>
      <Typography variant="h2">GREG COOPER REPTILES</Typography>
      <Typography variant="h5">
        Breeding boas, pythons, and colubrids since 1998
      </Typography>
      <Box
        sx={{
          my: 5,
          padding: 2,
          maxWidth: wideScreenWidth ? 600 : 400,
          backgroundColor: "#846D44",
          opacity: 0.925,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="body1" sx={{ mb: 2 }}>
            I've been keeping snakes since the late 90's. Check out the
            collection using the link above. I'm also doing updates on YouTube.
            Enjoy the site!
          </Typography>
          <a
            href={youTubeUrl}
            alt="GregCooperReptiles on YouTube"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3 style={{ color: "white" }}>
              GregCooperReptiles on{" "}
              <img src={YouTube} width="100" alt="YouTube" />
            </h3>
          </a>
        </Box>
        <Image
          src={EKing1}
          caption="1978, me holding an Eastern kingsnake, with the best bowl-cut in the neighborhood"
        />
        <Image
          src={EKing2}
          caption="2023, me holding an Eastern kingsnake, still the best haircut in the neighborhood"
        />
      </Box>
    </Paper>
  );
}
