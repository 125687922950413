import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
// import ImageGrid from "components/imagegrid";

import nightshade1 from "img/blackpines/20220723-DSC_6812.jpg";

// const imageData = [
// ];

const Nightshade = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Nightshade</title>
        <meta
          name="description"
          content="See the Black Pine Snakes (Pituophis melanoleucus lodingi)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/nightshade" />
      </Helmet>

      <HeaderText title="Nightshade" subtitle="Black Pine Snake" />

      <AnimalCard
        name="Nightshade"
        gender="female"
        birthyear="2021"
        imageName={nightshade1}
        traits=""
        acqYear="2022"
        acqNotes="Summer Sutherland, TSK"
        desc=""
      />

      <BodyText></BodyText>

      {/* <ImageGrid imageData={imageData} maxWidth="900" /> */}
    </ContentBox>
  );
};

export default Nightshade;
