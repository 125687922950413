import { Helmet } from "react-helmet";
import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import ImageGrid from "components/imagegrid";
import Video from "components/video";

// Images
import sunset1 from "img/leopardgeckos/20190827-DSC_7295.jpg";
import sunset2 from "img/leopardgeckos/2019-01-13 09.38.43.jpg";

// Cotton Sun
import cotton1 from "img/leopardgeckos/2019-01-13 09.45.53.jpg";
import cotton2 from "img/leopardgeckos/20190430-DSC_6750.jpg";
import cotton5 from "img/leopardgeckos/2018-09-09 10.32.55.jpg";
import cotton7 from "img/leopardgeckos/2019-03-20 08.50.31.jpg";
import cotton8 from "img/leopardgeckos/2019-03-20 08.51.39.jpg";
import cotton9 from "img/leopardgeckos/2019-05-04 11.25.40.jpg";
import cotton10 from "img/leopardgeckos/20181106-DSC_6100-7.jpg";

// Babies
import baby1 from "img/leopardgeckos/2019-04-26 10.33.04.jpg";
import baby2 from "img/leopardgeckos/20190827-DSC_7296.jpg";
import baby3 from "img/leopardgeckos/20191126-DSC_7883.jpg";
import baby4 from "img/leopardgeckos/20191126-DSC_8017.jpg";
import baby6 from "img/leopardgeckos/2019-04-26 10.31.10.jpg";
import baby7 from "img/leopardgeckos/20191126-DSC_8015.jpg";
import baby8 from "img/leopardgeckos/20191126-DSC_7871.jpg";

// Pinta

const albumData = [
  { img: sunset2, caption: "2019, Sunset" },
  { img: cotton5, caption: "2018, Cotton Sun" },
  { img: cotton10, caption: "2018, Cotton Sun" },
  { img: cotton1, caption: "2019, Cotton Sun" },
  { img: cotton2, caption: "2019, Cotton Sun" },
  { img: cotton7, caption: "2019, Cotton Sun, in her lay box" },
  { img: cotton8, caption: "2019, Cotton Sun, first eggs" },
  { img: cotton9, caption: "2019, Cotton Sun" },
  { img: baby6, caption: "2019, first baby gecko" },
  { img: baby1, caption: "2019, first baby gecko" },
  { img: baby2, caption: "2019, baby gecko" },
  { img: baby3, caption: "2019, baby gecko" },
  { img: baby4, caption: "2019, baby gecko" },
  { img: baby7, caption: "2019, baby gecko" },
  { img: baby8, caption: "2019, baby gecko" },
];

const animalGridData = [
  {
    id: "sunset",
    name: "Sunset",
    gender: "male",
    birthyear: "2017",
    img: sunset1,
    traits: "Het Albino, Poss Giant",
  },
];

const LeopardGecko = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Leopard Geckos</title>
        <meta
          name="description"
          content="See the Leopard Geckos (Eublepharis macularius)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/leopard-gecko" />
      </Helmet>
      <HeaderText title="Leopard Geckos" subtitle="Eublepharis macularius" />
      <AnimalGrid data={animalGridData} />
      <BodyText>
        The leopard geckos in our home have primarily been the interest of my
        youngest son but they are such cool lizards I have gotten into them too.
        Obviously they are everywhere in the pet market but once we started
        holding and interacting with them it was clear what the appeal is. Big
        chunky animals that aren't flighty or wiggly and they come in some
        incredible colors and patterns.
      </BodyText>
      <Video
        src="https://www.youtube.com/embed/_QzD75ldpQM"
        caption="Decorating My Lizard's Cage!"
      />
      <Video
        src="https://www.youtube.com/embed/djHybCWiPhI"
        caption="Leopard Gecko Fixer-Upper!"
      />
      <Video
        src="https://www.youtube.com/embed/D2x8z24oHuQ"
        caption="I Got a New Leopard Gecko!"
      />
      <BodyText>
        We got our first one, Sunset, for my son for a birthday present. He
        picked it out himself at the Las Vegas reptile expo that we went to.
        Sunset is absolutely stunning in color. Over the next few years my son
        bought two female leopard geckos and bred them to Sunset, but we have
        struggled to maintain the health of the females. Both of them passed
        away after laying eggs. Low calcium intake is probably the culprit. The
        deaths have been devastating for us so we're not ready to try and
        correct our husbandry yet with new animals.
      </BodyText>
      <ImageGrid imageData={albumData} maxWidth="900" />
    </ContentBox>
  );
};

export default LeopardGecko;
