import { Helmet } from "react-helmet";
import AnimalCard from "components/animalcard";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
// import Image from "components/image";
import ImageGrid from "components/imagegrid";

import hans1 from "img/jungles/JCP_001.jpg";
import hans2 from "img/jungles/DSCN0601.jpg";
import hans4 from "img/jungles/2021-05-01 20.36.00.jpg";
import hans5 from "img/jungles/20210121-DSC_9565.jpg";
import hans6 from "img/jungles/20220302-DSC_5653.jpg";
import hans7 from "img/jungles/20210507-DSC_0009.jpg";
import hans8 from "img/jungles/20230523-DSC_0491.jpg";
import hans9 from "img/jungles/20230523-DSC_0492.jpg";
import hans10 from "img/jungles/20230813-DSC_2210-Edit-Edit.jpg";

const imageData = [
  { img: hans1, caption: "early 2000s, Pirate Hans" },
  { img: hans2, caption: "early 2000s, Pirate Hans" },
  { img: hans4, caption: "2021, Pirate Hans, getting undressed" },
  { img: hans7, caption: "2021, Pirate Hans" },
  { img: hans6, caption: "2022, Pirate Hans" },
  { img: hans8, caption: "2023, Pirate Hans" },
  { img: hans9, caption: "2023, Pirate Hans" },
  { img: hans10, caption: "2023, Pirate Hans" },
];

const PirateHans = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Pirate Hans</title>
        <meta
          name="description"
          content="See the Jungle Carpet Pythons (Morelia spilota cheynei)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/piratehans" />
      </Helmet>
      <HeaderText title="PirateHans" subtitle="Jungle Carpet Python" />

      <AnimalCard
        name="Pirate Hans"
        gender="male"
        birthyear=""
        imageName={hans5}
        traits=""
        acqYear="1999"
        acqNotes="acquired as an adult"
        desc=""
      />

      <BodyText>
        Pirate Hans got his name from a son of mine who was 3 years old at the
        time. No, it's probably not a good idea to let small children name your
        pets. My son must have had a thing for pirates back then so that part
        makes sense, but I don't know where he got Hans from.
      </BodyText>
      <BodyText>
        Pirate Hans was a full-grown adult when I got him in 1999 and so I'm
        assuming he was born in 1995 or earlier. I know nothing of his origin.
        But as I write this text he could very likely be 30 years old by now.
      </BodyText>
      <BodyText>
        Hans has always been a good snake who doesn't bite and we've enjoyed him
        over the years. In efforts to see if he and Jinx will breed recently
        I've noticed that I've never seen Hans and Jinx locked, so I assume he's
        to blame for that, maybe? In any case, he's here to stay and enjoy
        whatever years of life he has left.
      </BodyText>

      <ImageGrid imageData={imageData} maxWidth="900" />
    </ContentBox>
  );
};

export default PirateHans;
