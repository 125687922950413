import { Helmet } from "react-helmet";
import AnimalGrid from "components/animalgrid";
import BodyText from "components/bodytext";
import ContentBox from "components/contentbox";
import HeaderText from "components/headertext";
import Image from "components/image";
import ImageGrid from "components/imagegrid";

// LOL
import eking2 from "img/easternkings/20190505-DSC_6881.jpg";
import eking3 from "img/easternkings/20210417-DSC_9911.jpg";
import eking6 from "img/easternkings/20210629-DSC_1677.jpg";
import eking10 from "img/easternkings/DSC_5979-1.jpg";

// Blister
import eking12 from "img/easternkings/Eastern Kingsnake (M) from GYP - 2.jpg";

import eking1 from "img/easternkings/larry_bev256 (2).jpg";
import eking4 from "img/easternkings/01020.jpg";
import eking5 from "img/easternkings/Img_2016-03-06_04407-2.jpg";

const albumData = [
  { img: eking10, caption: "2018, LOL" },
  { img: eking2, caption: "2019, LOL" },
  { img: eking6, caption: "2021, LOL" },
];

const animalGridData = [
  {
    id: "lol",
    name: "LOL",
    gender: "female",
    birthyear: "2015",
    img: eking3,
    traits: "",
  },
  {
    id: "blister",
    name: "Blister",
    gender: "male",
    birthyear: "2019",
    img: eking12,
    traits: "",
  },
];

const EasternKingsnake = () => {
  return (
    <ContentBox>
      <Helmet>
        <title>Eastern Kingsnakes</title>
        <meta
          name="description"
          content="See the Eastern Kingsnakes (Lampropeltis getula)
            that are being kept here and learn more about their captive husbandry."
        />
        <link rel="canonical" href="https://www.gcreptiles.com/eastern-kingsnake" />
      </Helmet>
      <HeaderText title="Eastern Kingsnakes" subtitle="Lampropeltis getula" />
      <AnimalGrid data={animalGridData} />
      <BodyText>
        I was raised in Fairfax, Virginia and my family, friends, and I spent
        every summer day outside in the woods, the fields, the lakes, and the
        swamps. This is where I learned to love finding reptiles and amphibians
        and I would bring some of them home to take care of for a little while.
        Eastern Kingsnakes were one of the most coveted finds but I only ever
        found one. I brought it home and my mom made me keep it in the garage,
        "in case it escapes", she said. Call it premonition or maybe my mom did
        something nefarious but sure enough my beautiful kingsnake wasn't in his
        cage one day and was never found again.
      </BodyText>
      <Image
        src={eking1}
        caption="1978, me holding a wild-caught Eastern kingsnake"
      />
      <BodyText>
        When I got older and my wife and I were living in Utah raising kids
        there I bought one and kept it for a couple of years until someone
        talked me into trading for it.
      </BodyText>
      <Image
        src={eking4}
        caption="2001, with an Eastern King in the collection"
      />
      <BodyText>
        Then finally in the mid 2010s I bought another one and vowed to always
        have Eastern kingsnakes again. I now own a pair of them and hope to
        breed them soon.
      </BodyText>
      <Image src={eking5} caption="2016, LOL joins the family" />
      <ImageGrid imageData={albumData} maxWidth="900" />
    </ContentBox>
  );
};

export default EasternKingsnake;
